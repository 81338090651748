import React from "react"
import { Card } from "react-bootstrap"

import "./CardItem.css"

import cardImage from "../../assets/background-studycard-doodles-01.png"
import StarRating from "../StarRating/StarRating"

export default function CardItem(props) {
  const Logo = props.logo
  return (
    <Card
      style={{
        width: "14rem",
        minHeight: "19rem",
        textAlign: "center",
        margin: "1rem auto",
        cursor: "pointer",
        border: props.active ? "1px solid #b1b3b3" : "1px solid lightgray",
        boxShadow: props.active ? "0 8px 6px -6px gray" : "none",
        transition: "all 0.4s ease",
        transform: props.active ? "scale(1.05,1.05)" : "none",
        borderRadius: "5px",
      }}
      onClick={props.onClick}
    >
      <div
        className={props.cardType}
        style={{ backgroundImage: `url(${cardImage})` }}
      >
        <div className="card-top-logoWrapper">
          <Logo className="card-logo" />
        </div>
      </div>
      <div
        style={{
          backgroundColor: `${props.titleColor}`,
          height: "1rem",
          paddingTop: "0.1rem",
        }}
      >
        <div
          style={{
            fontSize: "10px",
            textTransform: "uppercase",
            color: "white",
          }}
        >
          {props.cardTitle}
        </div>
      </div>
      <Card.Body
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          flex: "1",
          alignItems: "center",
        }}
      >
        <Card.Text>{props.cardName}</Card.Text>
      </Card.Body>
      <Card.Footer style={{ padding: ".5rem 1rem" }}>
        <li className="itemTotal">{props.items1}</li>
        <li className="itemTotal">{props.items2}</li>
        <li className="itemTotal">{props.items3}</li>
        {props.cardType === "card-skills" && (
          <div style={{ marginTop: "auto", display: "none" }}>
            <StarRating role={props.role} />{" "}
          </div>
        )}
      </Card.Footer>
    </Card>
  )
}
