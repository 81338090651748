import NavbarItem from "./NavbarItem"

export default function LoggedOut({ role }) {
  return (
    <>
      <NavbarItem path={`/${role}/login`} linkText="Login" eventKey={18} />

      <NavbarItem path={`/${role}/signup`} linkText="Registeer" eventKey={19} />
    </>
  )
}
