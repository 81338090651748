import { useDispatch, useSelector } from "react-redux"
import { Nav, NavDropdown } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { FaUserCircle } from "react-icons/fa"
import { logOut } from "../../store/user/action"
import {
  selectPersonalDetails,
  selectCurrentRole,
} from "../../store/user/selector"

export default function LoggedIn() {
  const dispatch = useDispatch()
  const personalDetails = useSelector(selectPersonalDetails)
  const currentRole = useSelector(selectCurrentRole)
  return (
    <>
      <Nav.Item style={{ marginRight: "1rem" }}>
        <NavDropdown
          title={<FaUserCircle />}
          id="collasible-nav-dropdown"
          alignRight
        >
          {personalDetails && (
            <>
              <NavDropdown.Item
                eventKey={7}
                as={NavLink}
                to={`/${
                  currentRole?.[0].role === "Recruiter"
                    ? "recruiter"
                    : "Student"
                    ? "student"
                    : null
                }/user-setting/general`}
              >
                {personalDetails.first_name} {personalDetails.last_name}
              </NavDropdown.Item>
            </>
          )}
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey={8}
          as={NavLink}
          to={`/${
            currentRole?.[0].role === "Recruiter"
              ? "recruiter"
              : "Student"
              ? "student"
              : null
          }/login`}
        >
          <button
            style={{
              border: "none",
              background: "transparent",
              padding: 0,
              color: "rgba(0,0,0,.5)",
            }}
            onClick={() => dispatch(logOut())}
          >
            Uitloggen
          </button>
        </Nav.Link>
      </Nav.Item>
    </>
  )
}
