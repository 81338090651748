export const selectRegisterKey = (state) => state.user.reset_key
export const selectApiList = (state) => state.user.scorecard_APIs
export const selectResetPasswordResult = (state) =>
  state.user.resetPasswordResult
export const selectUserRoles = (state) => state.user.available_roles
export const selectSessionKey = (state) => state.user.session_key
export const selectPersonalDetails = (state) => state.user.personalDetails
export const selectApi = (state) => state.user.api
export const selectCurrentRole = (state) => state.user.currentRole

//A/B tests experiment variant
export const selectExpVariant = (state) => state.user.experimentVariant
