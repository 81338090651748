export const dummyJobCards = [
  {
    id: 1,
    jobTitle: "Web developer",
    company: "ABC Group",
    level: "Junior",
    industry: "Game",
    location: "Amsterdam",
    postDate: new Date().toString(),
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    profile: "developer",
    studyLevel: "MBO 4",
    skillsArray: [
      {
        id: 1,
        name: "front-end development",
        description: "Can use front end framework",
        type: "technology",
      },
      {
        id: 2,
        name: "backend-end development",
        description: "Can use back end framework",
        type: "technology",
      },
      {
        id: 3,
        name: "communication",
        description: "Can communicate",
        type: "soft skill",
      },
      {
        id: 4,
        name: "english",
        description: "Can speak Enlgish",
        type: "language",
      },
    ],
  },
  {
    id: 2,
    jobTitle: "Hairdresser",
    company: "XYZ Shop",
    level: "Junior",
    industry: "Hair",
    location: "Amsterdam",
    postDate: new Date().toString(),
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    profile: "kapper",
    studyLevel: "MBO 2",
    skillsArray: [
      {
        id: 5,
        name: "cut hair",
        description: "Can cut hari",
        type: "hard skill",
      },
      {
        id: 6,
        name: "dye hair",
        description: "Can dye hair",
        type: "hard skill",
      },
      {
        id: 3,
        name: "communication",
        description: "Can communicate",
        type: "soft skill",
      },
      {
        id: 7,
        name: "dutch",
        description: "Can speak dutch",
        type: "language",
      },
    ],
  },
  {
    id: 3,
    jobTitle: "Farmer",
    company: "Far-mer",
    level: "Junior",
    industry: "Agriculture",
    location: "Zwolle",
    postDate: new Date().toString(),
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    profile: "Agriculture specialist",
    studyLevel: "MBO 2",
    skillsArray: [
      {
        id: 9,
        name: "Plant tree",
        description: "Can plant",
        type: "hard skill",
      },
      {
        id: 8,
        name: "soil check",
        description: "Can check soil quality",
        type: "hard skill",
      },
      {
        id: 3,
        name: "communication",
        description: "Can communicate",
        type: "soft skill",
      },
      {
        id: 4,
        name: "english",
        description: "Can speak Enlgish",
        type: "language",
      },
    ],
  },
]
