import { apiURL } from "../../config/constant"
import axios from "axios"
import { apiPost } from "../../utils/apiPostWrapper"
import {
  showMessageWithTimeout,
  appLoading,
  appDoneLoading,
} from "../appState/action"
import { enrollAStudy } from "../student/action"
import { selectSessionKey } from "./selector"

//FOR A/B TESTING

//Asking Backend for experience variant = which UI to render
export const EXPERIMENT_VARIANT = "EXPERIMENT_VARIANT"

export const getExperimentVariantSuccess = (experiments) => {
  return {
    type: EXPERIMENT_VARIANT,
    payload: experiments,
  }
}

export const getExperimentVariant = (experiments) => {
  return async (dispatch) => {
    try {
      const experimentConfig = await apiPost(
        "/api/common/analytics/experiment_variant",
        {
          experiments,
        }
      )
      await dispatch(
        getExperimentVariantSuccess(experimentConfig.data.experiments)
      )
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
      } else {
        console.log(error.message)
        dispatch(appDoneLoading())
      }
    }
  }
}
// Report exposures
export const reportExposure = (exposures) => {
  return async (dispatch) => {
    try {
      await apiPost("/api/common/analytics/report", {
        exposures,
      })
    } catch (error) {
      if (error.response) {
        console.log(error.response.data)
      } else {
        console.log(error.message)
        dispatch(appDoneLoading())
      }
    }
  }
}

//LOG OUT
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS"

export const logOut = () => {
  return {
    type: LOG_OUT_SUCCESS,
  }
}

// CHECK VALID SESSION KEY
export const SESSION_KEY_STILL_VALID = "SESSION_KEY_STILL_VALID"

const sessionKeyStillValid = (personalDetailsWithSessionKey) => ({
  type: SESSION_KEY_STILL_VALID,
  payload: personalDetailsWithSessionKey,
})

export const getPersionalDetailsWithStoredSessionKey = () => {
  return async (dispatch, getState) => {
    const sessionKey = selectSessionKey(getState())
    if (sessionKey === null) return
    dispatch(appLoading())
    try {
      const response = await apiPost(`/api/common/account/personal_details`, {
        session_key: sessionKey,
      })
      dispatch(sessionKeyStillValid(response.data))
      dispatch(appDoneLoading())
    } catch (error) {
      // if we get error, meaning sessionKey is not valid, log out
      dispatch(logOut())
      dispatch(appDoneLoading())
    }
  }
}

// GET PERSONAL DETAIL OF USER
export const PERSONAL_DETAILS = "PERSONAL_DETAILS"

const getPersonalDetailsSuccess = (persionalDetails) => {
  return {
    type: PERSONAL_DETAILS,
    payload: persionalDetails,
  }
}

export const getPersonalDetails = (session_key) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      const response = await apiPost(`/api/common/account/personal_details`, {
        session_key,
      })
      dispatch(getPersonalDetailsSuccess(response.data))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message)
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}
// WHAT API for USER
export const API_SUCCESS = "API_SUCCESS"
const apiSuccess = (api) => {
  return {
    type: API_SUCCESS,
    payload: api,
  }
}

// WHAT CURRENT ROLE of USER
export const CURRENT_ROLE_SUCCESS = "CURRENT_ROLE_SUCCESS"
const currentRoleSuccess = (role) => {
  return {
    type: CURRENT_ROLE_SUCCESS,
    payload: role,
  }
}

export const getCurrentRole = (session_key) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      const response = await apiPost(`/api/common/account/list_session_roles`, {
        session_key,
      })
      dispatch(currentRoleSuccess(response.data))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// LOG IN
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS"
const logInSuccess = (session_key) => {
  return {
    type: LOG_IN_SUCCESS,
    payload: session_key,
  }
}

export const logIn = (email, password, api, role, country_code, lang_code) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      //log in
      const logInResponse = await apiPost(`/api/common/account/login`, {
        email: email,
        password: password,
        api: api,
        role: role,
        country_code: country_code,
        lang_code: lang_code,
      })

      dispatch(logInSuccess(logInResponse.data))
      dispatch(apiSuccess(api))

      dispatch(showMessageWithTimeout("success", true, "Je bent ingelogd."))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

//Get list of roles for user
export const USER_ROLES_SUCCESS = "USER_ROLES_SUCCESS"

const getUserRolesSuccess = (roleList) => {
  return {
    type: USER_ROLES_SUCCESS,
    payload: roleList,
  }
}

export const getUserRoles = (email, password, api) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(`/api/common/account/list_user_roles`, {
        email,
        password,
        api,
      })
      dispatch(getUserRolesSuccess(response.data))

      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          `There is ${response.data.available_roles.length} role(s) available`
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

export const API_LIST_SUCCESS = "API_LIST_SUCCESS"

const getApiListSuccess = (apiList) => {
  return {
    type: API_LIST_SUCCESS,
    payload: apiList,
  }
}
export const getApiList = () => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      const response = await axios.get(
        `${apiURL}/api/common/account/list_scorecard_APIs`
      )
      dispatch(getApiListSuccess(response.data))

      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// Reset password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"

const resetPasswordSuccess = (sessionKey) => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: sessionKey,
  }
}
export const resetPassword = (email, resetKey, password, api, role) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(`/api/common/account/reset_password`, {
        email: email,
        reset_key: resetKey,
        password: password,
        api: api,
        role: role,
        country_code: "nl",
        lang_code: "nl",
      })
      dispatch(resetPasswordSuccess(response.data))

      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          `Wachtwoord is succesvol gewijzigd.`
        )
      )
      dispatch(logInSuccess(response.data))
      dispatch(apiSuccess(api))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message)
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

//SIGN UP
export const signUp = (
  first_name,
  last_name,
  email,
  work_city_id,
  country_code,
  lang_code,
  password,
  api,
  role,
  jobSearch,
  recruiterVisible,
  schoolName,
  firstYear,
  profile,
  lastYear,
  inviteCode
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/common/account/register_login`,

        {
          outcomes: {
            "student-registration-recruiter-contactable": recruiterVisible
              ? 1
              : 0,
          },
          email: email,
          first_name: first_name,
          last_name: last_name,
          country_code: country_code,
          lang_code: lang_code,
          work_city_id: work_city_id,
          user_job_search_state_id: api === "student" ? jobSearch : 0,
          recruiter_visible_default:
            api === "student" ? recruiterVisible : false,
          invitation_key: inviteCode,
          password: password,
          role: role,
          api: api,
        }
      )
      dispatch(logInSuccess(response.data))
      dispatch(apiSuccess(api))

      // ENROL ONLY FOR STUDENT
      api === "student" &&
        dispatch(
          enrollAStudy(
            response.data.session_key,
            schoolName,
            firstYear,
            profile,
            lastYear
          )
        )

      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log("error:", error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log("error:", error.message)
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// CHANGE PERSONAL DETAILS
export const CHANGE_PERSONALDETAILS = "CHANGE_PERSONALDETAILS"

const changePersonalDetailsSuccess = () => {
  return {
    type: CHANGE_PERSONALDETAILS,
  }
}

export const changePersonalDetails = (input) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      // eslint-disable-next-line
      const response = await apiPost(
        `/api/common/account/update_personal_details`,
        {
          ...input,
        }
      )
      dispatch(changePersonalDetailsSuccess)
      dispatch(getPersonalDetails(input.session_key))
      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          "Details zijn met succes gewijzigd ",
          5000
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// CHANGE PERSONAL SETTING :  work_city_id, recruiter_visible_default, and job_search_status_id

export const CHANGE_PERSONALSETTINGS = "CHANGE_PERSONALSETTINGS"

const changePersonalSettingsSuccess = () => {
  return {
    type: CHANGE_PERSONALSETTINGS,
  }
}

export const changePersonalSettings = (input) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      // eslint-disable-next-line
      const response = await apiPost(
        `/api/common/account/update_personal_settings`,
        {
          ...input,
        }
      )
      dispatch(changePersonalSettingsSuccess)
      dispatch(getPersonalDetails(input.session_key))
      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          "Details zijn met succes gewijzigd ",
          5000
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// CHANGE PASSOWRD
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"

const changePasswordSuccess = (response) => {
  return {
    type: CHANGE_PASSWORD,
    payload: response,
  }
}

export const changePassword = (sessionKey, email, password, new_password) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/common/account/change_password`,

        {
          session_key: sessionKey,
          email: email,
          password: password,
          new_password: new_password,
        }
      )
      dispatch(changePasswordSuccess(response.data))
      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          "Wachtwoord is succesvol gewijzigd.",
          5000
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// DELETE ACCOUNT
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"

const deleteAccountSuccess = () => {
  return {
    type: DELETE_ACCOUNT,
  }
}

export const deleteAccount = (sessionKey, email, password) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      // eslint-disable-next-line
      const response = await apiPost(
        `/api/common/account/delete`,

        {
          session_key: sessionKey,
          email: email,
          password: password,
        }
      )
      dispatch(deleteAccountSuccess())
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}
