import React, { useEffect } from "react"
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { selectSessionKey, selectCurrentRole } from "../../store/user/selector"
import { getMyStudies } from "../../store/student/action"
import UserSettingPage from "../Common/UserSetting/UserSettingPage"
import LogIn from "../Common/LogIn/LogIn"
import SignUp from "../Common/SignUp/SignUp"
import ScrollTop from "../../components/ScrollTop/ScrollTop"
import StudyWrapper from "./StudyWrapper"
import StudiesRoute from "../Student/Studies/StudiesRoute"
import AddStudy from "../Student/AddStudy/AddStudy"

function Student({ path }) {
  const sessionKey = useSelector(selectSessionKey)
  const roles = useSelector(selectCurrentRole)
  const currentRole = roles && roles[0].role
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if ((sessionKey, currentRole === "Student")) {
      dispatch(getMyStudies(sessionKey))
    } else if (sessionKey === null) {
      history.push(`${path}/login`)
    }
  }, [history, sessionKey, currentRole, path, dispatch])

  return (
    <div>
      <Switch>
        <Route
          path={`${path}/user-setting`}
          render={({ ...props }) => (
            <UserSettingPage {...props} role="student" />
          )}
        />
        <Route
          exact
          path={`${path}/studies`}
          render={({ ...props }) => (
            <StudiesRoute {...props} role="student" path={path} />
          )}
        />
        <Route
          path={`${path}/studies/add-study`}
          render={({ ...props }) => (
            <AddStudy {...props} role="student" path={path} />
          )}
        />

        <Route
          path={`${path}/studies/:profileId/:eduSystem`}
          render={({ ...props }) => (
            <StudyWrapper {...props} role="student" path={path} />
          )}
        />
      </Switch>
      <ScrollTop />
    </div>
  )
}

export default function StudentWrapper() {
  let { path } = useRouteMatch()
  const sessionKey = useSelector(selectSessionKey)

  return (
    <div
      style={{
        paddingBottom: "10rem",
        marginTop: "2.5rem",
      }}
    >
      <Switch>
        <Route exact path={`${path}`}>
          {sessionKey ? (
            <Redirect to={`${path}/studies`} />
          ) : (
            <Redirect to={`${path}/login`} />
          )}
        </Route>
        <Route
          path={`${path}/login`}
          render={({ props }) => <LogIn {...props} api="student" />}
        />

        <Route
          path={`${path}/signup`}
          render={({ props }) => <SignUp {...props} api="student" />}
        />
        {/* group routes that require sessionKey for student  */}
        <Route>
          <Student path={path} />
        </Route>
      </Switch>
    </div>
  )
}
