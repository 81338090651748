import React, { useEffect, useState } from "react"
import { IoArrowUpOutline } from "react-icons/io5"

import "./ScrollTop.css"

export default function ScrollTop() {
  const [isVisible, setIsVisible] = useState(false)
  // Top: 0 takes us all the way back to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  return (
    <>
      {isVisible && (
        <div
          className="scroll-to-top shadow cursor-pointer"
          onClick={scrollToTop}
        >
          <IoArrowUpOutline size={40} />
        </div>
      )}
    </>
  )
}
