import React from "react"
import { Row, Col } from "react-bootstrap"

import "./TitleRow.css"

export default function TitleRow(props) {
  return (
    <Row bsPrefix="row-wrapper">
      <Col bsPrefix="row-title">
        <h3>{props.title}</h3>
      </Col>
    </Row>
  )
}
