import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  APP_LOADING,
  APP_DONE_LOADING,
} from "./action"

const initialState = {
  message: null,
  loading: false,
}

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case APP_LOADING:
      return { ...state, loading: true }

    case APP_DONE_LOADING:
      return { ...state, loading: false }

    case SET_MESSAGE:
      return { ...state, message: action.payload }

    case CLEAR_MESSAGE:
      return { ...state, message: null }

    default:
      return state
  }
}
