import React, { useEffect } from "react"
import { Switch, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  selectSessionKey,
  selectPersonalDetails,
} from "../../store/user/selector"

import { selectStudyDetails } from "../../store/student/selector"
import { getMyStudyDetail } from "../../store/student/action"
import { trimSkillName } from "../../utils/trimSkillName"

import RouteWrapper from "../../utils/RouteWrapper"
import { rolesAndRoutes } from "../../config/constant"
import Profile from "../Student/Profile/Profile"
import CoreTasks2012 from "../Student/CoreTasks/CoreTasks2012"
import CoreTasks2016 from "../Student/CoreTasks/CoreTasks2016"
import Skills from "../Student/Skills/Skills"
import Scorecard from "../Student/Scorecards/Scorecard"
import WorkProcesses from "../Student/WorkProcesses/WorkProcesses"
import Competences from "../Student/Competences/Competences"
import StudentHeader from "../../components/Header/StudentHeader"
import StudentStatBar from "../../components/StatBar/StudentStatBar"
import ScrollTop from "../../components/ScrollTop/ScrollTop"

function StudyWrapper() {
  const profileId = parseInt(useParams().profileId)
  const educationSystem = parseInt(useParams().eduSystem)
  const myStudyDetail = useSelector(selectStudyDetails)
  const myPersonalDetails = useSelector(selectPersonalDetails)
  const sessionKey = useSelector(selectSessionKey)
  const history = useHistory()
  const dispatch = useDispatch()

  const pathUrl = {
    profile: `/student/studies/${profileId}/${educationSystem}/profile`,
    coreTasks: `/student/studies/${profileId}/${educationSystem}/coretasks`,
    workprocesses: `/student/studies/${profileId}/${educationSystem}/workprocesses`,
    competences: `/student/studies/${profileId}/${educationSystem}/competences`,
    skills: `/student/studies/${profileId}/${educationSystem}/skills`,
    scorecards: `/student/studies/${profileId}/${educationSystem}/scorecards`,
  }
  function getEducationSystem(system) {
    const systems = {
      1: "2012",
      2: "2016",
    }
    return systems[system]
  }
  const educationSystemValue = getEducationSystem(educationSystem)

  //TRIM SKILL NAME within studyDetail
  const modifiedStudyDetail = myStudyDetail && {
    ...myStudyDetail,
    skills: myStudyDetail.skills.map((skill) => trimSkillName(skill)),
  }

  useEffect(() => {
    if (sessionKey) {
      dispatch(getMyStudyDetail(sessionKey, profileId, educationSystemValue))
    }
  }, [sessionKey, dispatch, history, profileId, educationSystemValue])

  return (
    <div>
      <Switch>
        {myStudyDetail?.education_system_id === 1 && myPersonalDetails && (
          <>
            <StudentHeader
              studyDetail={modifiedStudyDetail}
              name={`${myPersonalDetails.first_name} ${myPersonalDetails.last_name}`}
            />
            <StudentStatBar
              studyDetail={modifiedStudyDetail}
              pathUrl={pathUrl}
            />

            <RouteWrapper
              exact
              path={rolesAndRoutes.profile.route}
              roles={rolesAndRoutes.profile.roles}
              Component={() => <Profile studyDetail={modifiedStudyDetail} />}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.coreTasks.route}
              roles={rolesAndRoutes.coreTasks.roles}
              Component={() => (
                <CoreTasks2012 studyDetail={modifiedStudyDetail} />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.workProcesses.route}
              roles={rolesAndRoutes.workProcesses.roles}
              Component={() => (
                <WorkProcesses
                  studyDetail={modifiedStudyDetail}
                  matrix="skill_matrix"
                />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.competences.route}
              roles={rolesAndRoutes.competences.roles}
              Component={() => (
                <Competences studyDetail={modifiedStudyDetail} />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.skills.route}
              roles={rolesAndRoutes.skills.roles}
              Component={() => (
                <Skills studyDetail={modifiedStudyDetail} role="student" />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.scorecards.route}
              roles={rolesAndRoutes.scorecards.roles}
              Component={() => (
                <Scorecard
                  studyDetail={modifiedStudyDetail}
                  matrix="skill_matrix"
                />
              )}
            />
          </>
        )}
        {myStudyDetail?.education_system_id === 2 && myPersonalDetails && (
          <>
            <StudentHeader
              studyDetail={modifiedStudyDetail}
              name={`${myPersonalDetails.first_name} ${myPersonalDetails.last_name}`}
            />
            <StudentStatBar
              studyDetail={modifiedStudyDetail}
              pathUrl={pathUrl}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.profile.route}
              roles={rolesAndRoutes.profile.roles}
              Component={() => <Profile studyDetail={modifiedStudyDetail} />}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.coreTasks.route}
              roles={rolesAndRoutes.coreTasks.roles}
              Component={() => (
                <CoreTasks2016 studyDetail={modifiedStudyDetail} />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.workProcesses.route}
              roles={rolesAndRoutes.workProcesses.roles}
              Component={() => (
                <WorkProcesses
                  studyDetail={modifiedStudyDetail}
                  matrix="competence_matrix"
                />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.competences.route}
              roles={rolesAndRoutes.competences.roles}
              Component={() => (
                <Competences studyDetail={modifiedStudyDetail} />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.skills.route}
              roles={rolesAndRoutes.skills.roles}
              Component={() => (
                <Skills studyDetail={modifiedStudyDetail} role="student" />
              )}
            />
            <RouteWrapper
              exact
              path={rolesAndRoutes.scorecards.route}
              roles={rolesAndRoutes.scorecards.roles}
              Component={() => (
                <Scorecard
                  studyDetail={modifiedStudyDetail}
                  matrix="competence_matrix"
                />
              )}
            />
          </>
        )}
      </Switch>
      <ScrollTop />
    </div>
  )
}

export default StudyWrapper
