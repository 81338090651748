import {
  CREATE_JOB_CARD,
  SEARCH_CANDIDATES,
  CLEAR_RESULTS,
  GET_CANDIDATE_STUDIES,
  GET_CANDIDATE_STUDY_DETAIL,
  JOB_CARD_DETAIL,
} from "./action"
import { dummyJobCards } from "../../pages/Recruiter/JobCard/Dummy"

const initialState = {
  jobCards: dummyJobCards,
  candidates: [],
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case JOB_CARD_DETAIL:
      let selectedJobCard = state.jobCards.find(
        (card) => card.id === action.payload
      )
      return { ...state, jobCard: selectedJobCard }
    case CREATE_JOB_CARD:
      return { ...state, jobCards: [action.payload, ...state.jobCards] }
    case SEARCH_CANDIDATES:
      return { ...state, candidates: action.payload }
    case CLEAR_RESULTS:
      return { ...state, candidates: action.payload }
    case GET_CANDIDATE_STUDIES:
      return { ...state, candidateStudies: action.payload }
    case GET_CANDIDATE_STUDY_DETAIL:
      return { ...state, candidateStudyDetail: action.payload }
    default:
      return state
  }
}
