import React from "react"

import "./Header.css"

import userImage from "../../assets/user.png"
import { Container } from "react-bootstrap"

export default function RecruiterHeader({ name }) {
  return (
    <div className="header">
      <Container className="header-wrapper d-flex flex-column justify-content-center align-items-center">
        <img
          src={userImage}
          alt="headerImage"
          width="50"
          height="50"
          style={{ margin: "0.5rem" }}
        />
        <h5>{name}</h5>
        <h5>Recruiter</h5>
        <p>Recruitment Agency</p>
      </Container>
    </div>
  )
}
