import React from "react"
import { Container } from "react-bootstrap"

import "./Header.css"

import hatImage from "../../assets/mortarboard.png"

export default function StudentHeader({ studyDetail, name }) {
  const {
    profile_name,
    school_name,
    national_qualification_level,
    profile_codes,
    study_started_year,
    study_stopped_year,
    education_name,
  } = studyDetail

  // const crebo = profile_codes.find((code) => code.code_name === "CREBO");
  return (
    <div className="header">
      <Container className="header-wrapper d-flex flex-column justify-content-center align-items-center">
        <img src={hatImage} alt="headerImage" width="64" height="64" />{" "}
        <h5>{name}</h5>
        <h3 style={{ fontWeight: "bold" }} className="header-profile-name">
          {profile_name}
        </h3>
        <h5> Studie: {education_name}</h5>
        <p style={{ marginBottom: "0.5rem" }}>
          StudyCard - {school_name} - {national_qualification_level.name} -
          Niveau {national_qualification_level.nqf_level} - CREBO{" "}
          {profile_codes.find((code) => code.code_name === "CREBO").code}
        </p>
        <p>
          Studiejaar: {study_started_year} - {study_stopped_year}
        </p>
      </Container>
    </div>
  )
}
