import { useSelector } from "react-redux";
import { selectApi } from "../store/user/selector";

const RBAC = ({ allowedRoles, children }) => {
  let userRole = useSelector(selectApi);

  function checkAccess(userRole, allowedRoles) {
    return allowedRoles.includes(userRole);
  }
  const access = checkAccess(userRole, allowedRoles);
  return access && children;
};

export default RBAC;
