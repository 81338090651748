import {
  RESET_PASSWORD_SUCCESS,
  API_LIST_SUCCESS,
  CURRENT_ROLE_SUCCESS,
  USER_ROLES_SUCCESS,
  LOG_IN_SUCCESS,
  API_SUCCESS,
  LOG_OUT_SUCCESS,
  PERSONAL_DETAILS,
  SESSION_KEY_STILL_VALID,
  CHANGE_PASSWORD,
  CHANGE_PERSONALDETAILS,
  DELETE_ACCOUNT,
  EXPERIMENT_VARIANT,
} from "./action"

const initialState = {
  session_key: localStorage.getItem("session_key"),
  resetPasswordResult: "",
  api: localStorage.getItem("api"),
}
let experimentVariant
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case API_LIST_SUCCESS:
      return { ...state, ...action.payload }
    case RESET_PASSWORD_SUCCESS:
      localStorage.setItem("session_key", action.payload.session_key)
      return { ...state, ...action.payload }
    case CHANGE_PASSWORD:
      return { ...state }
    case CHANGE_PERSONALDETAILS:
      return { ...state }
    case USER_ROLES_SUCCESS:
      return { ...state, ...action.payload }
    case LOG_IN_SUCCESS:
      localStorage.setItem("session_key", action.payload.session_key)
      return { ...state, ...action.payload }
    case API_SUCCESS:
      localStorage.setItem("api", action.payload)
      return { ...state, api: action.payload }

    case CURRENT_ROLE_SUCCESS:
      // localStorage.setItem("currentRole", action.payload.roles);
      return { ...state, currentRole: action.payload.roles }

    case PERSONAL_DETAILS:
      return { ...state, personalDetails: action.payload }
    case SESSION_KEY_STILL_VALID:
      return { ...state, personalDetails: action.payload }

    case LOG_OUT_SUCCESS:
      localStorage.removeItem("session_key")
      localStorage.removeItem("api")
      return { ...initialState, session_key: null, api: null }
    case DELETE_ACCOUNT:
      localStorage.removeItem("session_key")
      localStorage.removeItem("api")
      return { ...initialState, session_key: null, api: null }
    case EXPERIMENT_VARIANT:
      experimentVariant = {
        ...state.experimentVariant,
        ...action.payload,
      }

      sessionStorage.setItem(
        "experimentVariant",
        JSON.stringify(experimentVariant)
      )
      return {
        ...state,
        experimentVariant,
      }
    default:
      return state
  }
}
