import axios from "axios"
import { apiURL } from "../config/constant"

export const apiPost = (apiPath, input) => {
  const currentpath = window.location.pathname
  const { exposures, outcomes, ...requestInput } = input
  const tabId = sessionStorage.getItem("tabId")

  return axios.post(`${apiURL}/${apiPath}`, {
    analytics: { tab_id: tabId, page: currentpath, outcomes, exposures },
    ...requestInput,
  })
}
