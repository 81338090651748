import { apiPost } from "../../utils/apiPostWrapper"
import {
  showMessageWithTimeout,
  appLoading,
  appDoneLoading,
} from "../appState/action"

// GET A JOB CARD DETAIL
export const JOB_CARD_DETAIL = "JOB_CARD_DETAIL"

const getJobCardDetailSuccess = (id) => {
  return {
    type: JOB_CARD_DETAIL,
    payload: id,
  }
}

export const getJobCard = (id) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      dispatch(getJobCardDetailSuccess(id))
      dispatch(appDoneLoading())
    } catch (error) {
      console.log(error.response.data)
      dispatch(appDoneLoading())
    }
  }
}

// Create a job card
export const CREATE_JOB_CARD = "CREATE_JOB_CARD"

const createJobCardSuccess = (jobCard) => {
  return {
    type: CREATE_JOB_CARD,
    payload: jobCard,
  }
}

export const createJobCard = (
  id,
  postDate,
  jobTitle,
  company,
  level,
  industry,
  location,
  description,
  profile,
  studyLevel,
  skillsArray
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      dispatch(
        createJobCardSuccess({
          id,
          postDate,
          jobTitle,
          company,
          level,
          industry,
          location,
          description,
          profile,
          studyLevel,
          skillsArray,
        })
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// SEARCH CANDIDATE ACTION
export const SEARCH_CANDIDATES = "SEARCH_CANDIDATES"

const searchCandidatesSuccess = (candidates) => {
  return {
    type: SEARCH_CANDIDATES,
    payload: candidates,
  }
}
export const searchCandidates = (
  session_key,
  search_terms,
  job_title_id,
  job_city_id,
  job_skill_ids
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/recruiter/search/candidates`,

        {
          session_key,
          search_terms,
          job_title_id: job_title_id.length > 0 ? job_title_id[0].id : null,
          job_city_id: job_city_id.length > 0 ? job_city_id[0].id : null,
          job_skill_ids:
            job_skill_ids.length > 0 ? job_skill_ids.map((s) => s.id) : null,
        }
      )

      dispatch(searchCandidatesSuccess(response.data.candidates))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// CLEAR SEARCH RESULT
export const CLEAR_RESULTS = "CLEAR_RESULTS"

const clearCandidatesSuccess = (candidates) => {
  return {
    type: CLEAR_RESULTS,
    payload: candidates,
  }
}
export const clearCandidates = () => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    dispatch(clearCandidatesSuccess([]))

    dispatch(appDoneLoading())
  }
}

//GET CANDIDATE STUDIES for RECRUITER
export const GET_CANDIDATE_STUDIES = "GET_CANDIDATE_STUDIES"

const getCandidateStudiesSuccess = (candidateStudies) => {
  return {
    type: GET_CANDIDATE_STUDIES,
    payload: candidateStudies,
  }
}

export const getCandidateStudies = (sessionKey, candidateId) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())

    try {
      const response = await apiPost(`/api/recruiter/candidate/list_studies`, {
        session_key: sessionKey,
        protected_anonymous_id: candidateId,
      })

      dispatch(getCandidateStudiesSuccess(response.data.studies))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// GET STUDY DETAIL  OF CANDIDATE FOR RECRUITER
export const GET_CANDIDATE_STUDY_DETAIL = "GET_CANDIDATE_STUDY_DETAIL"

const getCandidateStudyDetailSuccess = (study) => {
  return {
    type: GET_CANDIDATE_STUDY_DETAIL,
    payload: study,
  }
}

export const getCandidateStudyDetail = (
  sessionKey,
  candidateId,
  profileId,
  eduSystem
) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/recruiter/candidate/study_details_mbo_${eduSystem}`,

        {
          session_key: sessionKey,
          protected_anonymous_id: candidateId,
          profile_id: profileId,
        }
      )

      dispatch(getCandidateStudyDetailSuccess(response.data))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}
