import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import { selectJobCard } from "../../../store/recruiter/selector"
import { getJobCard } from "../../../store/recruiter/action"

const JobCardDetail = ({ role }) => {
  const jobCardId = parseInt(useParams().id)
  // console.log("param", jobCardId)
  const jobCard = useSelector(selectJobCard)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!jobCard) {
      dispatch(getJobCard(jobCardId))
    }
  }, [])
  const skillTypes = jobCard && [
    ...new Set(jobCard.skillsArray.map((skill) => skill.type)),
  ]
  return (
    <div className="job-card-board container light-style flex-grow-1 pt-5">
      <h4 className="font-weight-bold py-2 mb-3">JobCard</h4>
      <div className="bg-white shadow rounded-lg p-3 d-">
        {jobCard && (
          <>
            <h5 className="font-weight-bold"> {jobCard.jobTitle}</h5>{" "}
            <h6 className="">{jobCard.company}</h6>
            <h6 className="">{jobCard.location.name}</h6>
            <div className="">{jobCard.studyLevel} </div>
            <div>Post date: {jobCard.postDate}</div>
            <p>Description: {jobCard.description}</p>
            <div>
              {skillTypes.map((type, index) => {
                const skillsPerType = jobCard.skillsArray.filter(
                  (skill) => skill.type === type
                )
                return (
                  <div key={index}>
                    <div>
                      {type}:{" "}
                      <ul className="pl-5">
                        {skillsPerType.map((skill) => (
                          <li key={skill.id}> {skill.name} </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default JobCardDetail
