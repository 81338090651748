import {
  GET_MY_STUDIES,
  GET_A_STUDY_DETAIL,
  SORT_CARDS,
  ENROLL_SUCCESS,
} from "./action"
import { LOG_OUT_SUCCESS, DELETE_ACCOUNT } from "../user/action"

const initialState = {
  myStudies: [],
  studyDetail: JSON.parse(localStorage.getItem("studyDetail")),
}

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case ENROLL_SUCCESS:
      return { ...state, myStudies: action.payload.studies }
    case GET_MY_STUDIES:
      return { ...state, myStudies: action.payload }
    case GET_A_STUDY_DETAIL:
      localStorage.setItem("studyDetail", JSON.stringify(action.payload))
      return { ...state, studyDetail: action.payload }
    case SORT_CARDS:
      // const sortedCards = action.payload.sort((a, b) => a.name.localeCompare(b.name))
      return {
        ...state,
        studyDetail: state.studyDetail.core_tasks.sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
      }
    case LOG_OUT_SUCCESS:
      localStorage.removeItem("studyDetail")
      return { ...initialState, myStudies: [], studyDetail: null }
    case DELETE_ACCOUNT:
      localStorage.removeItem("studyDetail")
      return { ...initialState, myStudies: [], studyDetail: null }

    default:
      return state
  }
}
