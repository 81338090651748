import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import TextareaAutosize from "react-textarea-autosize"
import { v4 as uuidv4 } from "uuid"

import { selectSessionKey } from "../../../store/user/selector"
import { createJobCard } from "../../../store/recruiter/action"
import { apiPost } from "../../../utils/apiPostWrapper"
let jobcardId = 10
const CreateJobCard = ({ role }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const sessionKey = useSelector(selectSessionKey)
  const [cityOptions, setCityOptions] = useState([])
  const [isCityLoading, setIsCityLoading] = useState(false)
  const [profileOptions, setProfileOptions] = useState([])
  const [isProfileLoading, setIsProfileLoading] = useState(false)
  const [skilloptions, setSkillOptions] = useState([])
  const [selectedSkillOptions, setSelectedSkillOptions] = useState([])
  const [isSkillsLoading, setIsSkillsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      skillsArray: [],
    },
    shouldUnregister: false,
  })

  const [
    jobTitle,
    company,
    level,
    industry,
    description,
    location,
    profile,
    studyLevel,
    skillsArray,
  ] = watch([
    "jobTitle",
    "company",
    "level",
    "industry",
    "description",
    "location",
    "profile",
    "studyLevel",
    "skillsArray",
  ])

  // GET CITIES
  const loadCityOptions = async (searchTerm) => {
    try {
      setIsCityLoading(true)
      const citiesResponse = await apiPost(`/api/common/search/city`, {
        search_term: searchTerm,
      })
      setCityOptions(citiesResponse.data.cities)
      setIsCityLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsCityLoading(false)
    }
  }

  // GET PROFILE
  const loadProfileOptions = async (searchTerm) => {
    try {
      setIsProfileLoading(true)
      const profilesResponse = await apiPost(`/api/common/search/job_title`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setProfileOptions(profilesResponse.data.job_titles)
      setIsProfileLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsProfileLoading(false)
    }
  }

  //load skills options
  const loadSkillOptions = async (searchTerm) => {
    try {
      setIsSkillsLoading(true)
      const skillResponse = await apiPost(`/api/common/search/skill`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setSkillOptions(skillResponse.data.skills)
      setIsSkillsLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsSkillsLoading(false)
    }
  }

  // handle profile change
  const handleProfileChange = async (selectedProfile) => {
    try {
      const suggested = await apiPost(
        `/api/recruiter/search/relevant_skills_for_job`,
        {
          session_key: sessionKey,
          job_title_id: selectedProfile.id,
        }
      )
      setSelectedSkillOptions(suggested.data.skills.slice(0, 10))
    } catch (error) {
      console.log("error", error)
    }
  }

  const filterBy = () => true
  const id = jobcardId++
  const postDate = new Date().toString()
  const handleCreateJobCard = () => {
    dispatch(
      createJobCard(
        id,
        postDate,
        jobTitle,
        company,
        level,
        industry,
        location[0],
        description,
        profile[0],
        studyLevel,
        skillsArray
      )
    )
    history.push(`/${role}/job-cards-overview`)
  }
  return (
    <div className="container  flex-grow-1 py-5">
      <h3 className="font-weight-bold py-2 mb-3">Een JobCard Aanmaken</h3>
      <div className="shadow p-4 bg-white">
        <form onSubmit={handleSubmit(handleCreateJobCard)}>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">JobCard Title</label>
                <input
                  className="form-control"
                  name="jobTitle"
                  type="text"
                  id="jobTitle"
                  {...register("jobTitle", {
                    required: true,
                  })}
                />
                {errors.jobTitle?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">Bedrijf</label>
                <input
                  className="form-control"
                  name="company"
                  type="text"
                  id="company"
                  {...register("company", {
                    required: true,
                  })}
                />
                {errors.company?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">Job Niveau</label>
                <select
                  className="form-control"
                  name="level"
                  type="text"
                  id="level"
                  {...register("level", {
                    required: true,
                  })}
                >
                  <option value="senior">Senior</option>
                  <option value="medior">Medior</option>
                  <option value="junior">Junior</option>
                  <option value="intern">Intern</option>
                  <option value="trainee">Trainee</option>
                </select>

                {errors.level?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">Sector/Industrie</label>
                <input
                  className="form-control"
                  name="industry"
                  type="text"
                  id="industry"
                  {...register("industry", {
                    required: true,
                  })}
                />
                {errors.industry?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">Plaats</label>
                <Controller
                  control={control}
                  name="location"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AsyncTypeahead
                      {...field}
                      filterBy={filterBy}
                      labelKey={(option) => `${option.name}`}
                      isLoading={isCityLoading}
                      minLength={2}
                      onSearch={loadCityOptions}
                      options={cityOptions}
                      bsSize="sm"
                      id="job-city"
                      placeholder="Kies een stad..."
                    />
                  )}
                />
                {errors.location?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label className="font-weight-bold">Functieomschrijving</label>
                <TextareaAutosize
                  className="form-control"
                  maxRows={10}
                  minRows={8}
                  {...register("description", {
                    required: true,
                  })}
                />

                {errors.description?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="font-weight-bold">Afgestudeerd Profiel</label>
                <Controller
                  control={control}
                  name="profile"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <AsyncTypeahead
                      {...field}
                      filterBy={filterBy}
                      labelKey={(option) => `${option.name}`}
                      id="graduate-profile"
                      isLoading={isProfileLoading}
                      minLength={3}
                      onSearch={loadProfileOptions}
                      options={profileOptions}
                      onChange={([selected]) => {
                        field.onChange(selected)
                        handleProfileChange(selected)
                      }}
                      bsSize="sm"
                      placeholder="Kies een profiel..."
                    />
                  )}
                />
                {errors.profile?.type === "required" && (
                  <p className="error__message">Deze invoer is vereist</p>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="font-weight-bold">Study Niveau</label>
                <select
                  className="form-control"
                  type="text"
                  id="studyLevel"
                  {...register("studyLevel", {
                    required: true,
                  })}
                >
                  <option value="MBO 1">MBO 1</option>
                  <option value="MBO 2">MBO 2</option>
                  <option value="MBO3">MBO 3</option>
                  <option value="MBO 4">MBO 4</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-6">
              <div className="form-group">
                <label className="font-weight-bold">Vaardigheden</label>
                <Controller
                  control={control}
                  name="skillsArray"
                  render={({ field }) => (
                    <AsyncTypeahead
                      {...field}
                      filterBy={filterBy}
                      labelKey={(option) => `${option.name}`}
                      id="Add skill dropdown for search"
                      isLoading={isSkillsLoading}
                      minLength={3}
                      onSearch={loadSkillOptions}
                      onChange={(selected) => setSelectedSkillOptions(selected)}
                      options={skilloptions}
                      onBlur={() => setIsOpen(false)}
                      onFocus={() => setIsOpen(true)}
                      open={isOpen}
                      placeholder="Kies een vaardigheid..."
                    />
                  )}
                />
              </div>
            </div> */}
          </div>
          <div className="">
            <div className="form-group ">
              <label className="font-weight-bold"></label>

              <div className="table-responsive text-center">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="w-25">Vaardigheden</th>
                      <th className="w-25">Omschrijving</th>
                      <th>Hard Skill</th>
                      <th>Soft Skill</th>
                      <th>Technology</th>
                      <th>Methodology</th>
                      <th>Language</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSkillOptions.map((item, index) => {
                      skillsArray[index] = {
                        ...skillsArray[index],
                        name: item.name,
                        id: item.id,
                      }
                      console.log("skilsl", selectedSkillOptions)
                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>

                          <td>
                            <Controller
                              control={control}
                              name={`skillsArray.${index}.description`}
                              // rules={{
                              //   required: true,
                              // }}
                              defaultValue=""
                              render={({ field }) => (
                                <TextareaAutosize
                                  {...field}
                                  className="form-control cursor-pointer"
                                />
                              )}
                            />
                          </td>

                          <Controller
                            control={control}
                            name={`skillsArray.${index}.type`}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => (
                              <>
                                {[
                                  "hard skill",
                                  "soft skill",
                                  "technology",
                                  "methodology",
                                  "language",
                                ].map((type, index) => {
                                  return (
                                    <td key={index}>
                                      <label>
                                        {" "}
                                        <input
                                          {...field}
                                          className="cursor-pointer"
                                          type="radio"
                                          value={type}
                                        />
                                      </label>
                                    </td>
                                  )
                                })}
                              </>
                            )}
                          />
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button className="button_primary" type="submit">
            JobCard Aanmaken
          </button>
        </form>
      </div>
    </div>
  )
}

export default CreateJobCard
