import React, { useState, useMemo, useEffect } from "react"
import { Link } from "react-router-dom"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { useSelector, useDispatch } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import { BsSearch, BsFillPersonLinesFill } from "react-icons/bs"
import { FaFilter } from "react-icons/fa"

import "./DashBoard.css"

import candidateImage from "../../../assets/user2.png"
import Table from "./Table"
import RecruiterHeader from "../../../components/Header/RecruiterHeader"
import RecruiterStatBar from "../../../components/StatBar/RecruiterStatBar"
import { apiPost } from "../../../utils/apiPostWrapper"
import {
  selectSessionKey,
  selectPersonalDetails,
} from "../../../store/user/selector"
import { getPersonalDetails } from "../../../store/user/action"
import { selectCandidateResults } from "../../../store/recruiter/selector"
import {
  searchCandidates,
  clearCandidates,
} from "../../../store/recruiter/action"

export default function Search() {
  const sessionKey = useSelector(selectSessionKey)
  const candidatesResults = useSelector(selectCandidateResults)
  const personalDetails = useSelector(selectPersonalDetails)
  const dispatch = useDispatch()
  const [options, setOptions] = useState([])
  const [selectedSkillOptions, setSelectedSkillOptions] = useState([])
  const [cityOptions, setCityOptions] = useState([])
  const [selectedCity, setSelectedCity] = useState([])
  const [profileOptions, setProfileOptions] = useState([])
  const [selectedProfile, setSelectedProfile] = useState([])
  const [isProfileLoading, setIsProfileLoading] = useState(false)
  const [isCityLoading, setIsCityLoading] = useState(false)
  const [isKeyWordLoading, setIsKeyWordLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  // calculated number match of skills/competences with search
  function match(keyword, selectedKeywords) {
    return keyword.filter((id) =>
      selectedKeywords.map((s) => s.id).includes(id)
    ).length
  }

  //get name
  useEffect(() => {
    if (sessionKey) {
      dispatch(getPersonalDetails(sessionKey))
    }
  }, [dispatch, sessionKey])

  //load skills options
  const loadOptions = async (searchTerm) => {
    try {
      setIsKeyWordLoading(true)
      const skillResponse = await apiPost(`/api/common/search/skill`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setOptions(skillResponse.data.skills)
      setIsKeyWordLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsKeyWordLoading(false)
    }
  }

  // GET CITIES
  const loadCityOptions = async (searchTerm) => {
    try {
      setIsCityLoading(true)
      const citiesResponse = await apiPost(`/api/common/search/city`, {
        search_term: searchTerm,
      })
      setCityOptions(citiesResponse.data.cities)
      setIsCityLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsCityLoading(false)
    }
  }

  // GET PROFILE
  const loadProfileOptions = async (searchTerm) => {
    try {
      setIsProfileLoading(true)
      const profilesResponse = await apiPost(`/api/common/search/job_title`, {
        session_key: sessionKey,
        search_term: searchTerm,
      })
      setProfileOptions(profilesResponse.data.job_titles)
      setIsProfileLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsProfileLoading(false)
    }
  }

  // SEARCH CANDIDATES
  const handleSearch = async () => {
    dispatch(
      searchCandidates(
        sessionKey,
        "bug",
        selectedProfile,
        selectedCity,
        selectedSkillOptions
      )
    )
  }

  const handleSelectedProfile = async (selectedProfile) => {
    setSelectedProfile(selectedProfile)
    try {
      const suggested = await apiPost(
        `/api/recruiter/search/relevant_skills_for_job`,
        {
          session_key: sessionKey,
          job_title_id: selectedProfile[0].id,
        }
      )
      setSelectedSkillOptions(suggested.data.skills)
    } catch (error) {
      console.log("error", error)
    }
  }

  const filterBy = () => true

  const columns = useMemo(
    () => [
      {
        Header: "No.",
        accessor: (row, index) => {
          return index + 1
        },
      },
      {
        Header: "",
        accessor: "image",
        Cell: () => {
          return (
            <img
              src={candidateImage}
              alt="candidate"
              style={{ height: "2.2rem", width: "3rem" }}
            />
          )
        },
      },
      {
        Header: "Naam",
        accessor: (values) => {
          const first_name = values.first_name
          const last_name = values.last_name
          return first_name + " " + last_name
        },
      },
      {
        Header: "Afstand",
        accessor: (values) => {
          const distance = Math.round(values.distance_km)
          return distance
        },
        Cell: ({ cell }) => {
          const { value } = cell
          return <>{value} km </>
        },
      },
      {
        Header: "Vaardigheden",
        accessor: (values) => {
          const matchedSkills = match(values.skill_ids, selectedSkillOptions)
          return matchedSkills
        },
        Cell: ({ cell }) => {
          const { value } = cell
          return (
            <>
              {value}/{selectedSkillOptions.length}
            </>
          )
        },
      },
      {
        Header: "StudyCard",
        accessor: (values) => {
          const candidate = values
          return candidate
        },

        Cell: ({ cell }) => {
          const { value } = cell
          return (
            <Link
              to={`/recruiter/candidate/${value.first_name}/${value.last_name}/${value.protected_anonymous_id}/profile`}
              target="_blank"
            >
              <button className="button-viewCandidate">Bekijken</button>
            </Link>
          )
        },
      },
    ],
    [selectedSkillOptions]
  )
  function resetInput() {
    setSelectedProfile([])
    setSelectedCity([])
    setSelectedSkillOptions([])
    dispatch(clearCandidates())
  }
  return (
    <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
      {personalDetails && (
        <RecruiterHeader
          name={`${personalDetails.first_name} ${personalDetails.last_name}`}
        />
      )}
      <RecruiterStatBar />
      <Container style={{ padding: "0.5rem ", minHeight: "800px" }}>
        <Row className="mx-0">
          <Col md={12}>
            <div className="grid search">
              <div className="grid-body">
                <Row>
                  <Col md={4}>
                    <h4 className="grid-title">
                      <FaFilter size={18} style={{ marginRight: "0.3rem" }} />
                      Zoeken
                    </h4>
                    <div>
                      <h6>Profiel</h6>
                    </div>
                    <div className="search-bar" style={{ marginLeft: 0 }}>
                      <AsyncTypeahead
                        filterBy={filterBy}
                        labelKey={(option) => `${option.name}`}
                        id="Add profile dropdown for search"
                        isLoading={isProfileLoading}
                        minLength={3}
                        onSearch={loadProfileOptions}
                        onChange={handleSelectedProfile}
                        options={profileOptions}
                        selected={selectedProfile}
                        bsSize="sm"
                        placeholder="Kies een profiel..."
                      />
                    </div>
                    <div>
                      <h6>Plaats</h6>
                    </div>
                    <div className="search-bar" style={{ marginLeft: 0 }}>
                      <AsyncTypeahead
                        filterBy={filterBy}
                        labelKey={(option) => `${option.name}`}
                        id="Add city dropdown for search"
                        isLoading={isCityLoading}
                        minLength={2}
                        onSearch={loadCityOptions}
                        onChange={(selected) => setSelectedCity(selected)}
                        options={cityOptions}
                        selected={selectedCity}
                        bsSize="sm"
                        placeholder="Kies een stad..."
                      />
                    </div>
                    <div>
                      <h6>Vaardigheden</h6>
                    </div>
                    <div className="search-bar">
                      <AsyncTypeahead
                        filterBy={filterBy}
                        labelKey={(option) => `${option.name}`}
                        id="Add skill dropdown for search"
                        isLoading={isKeyWordLoading}
                        minLength={3}
                        onSearch={loadOptions}
                        onChange={(selected) =>
                          setSelectedSkillOptions(selected)
                        }
                        options={options}
                        selected={selectedSkillOptions}
                        onBlur={() => setIsOpen(false)}
                        onFocus={() => setIsOpen(true)}
                        multiple
                        open={isOpen}
                        placeholder="Kies een vaardigheid..."
                      />
                    </div>
                    <button
                      className="search-button search"
                      onClick={handleSearch}
                      disabled={
                        selectedProfile.length > 0 ||
                        selectedCity.length > 0 ||
                        selectedSkillOptions.length > 0
                          ? false
                          : true
                      }
                    >
                      <BsSearch /> Zoeken
                    </button>
                    <button
                      className="search-button reset"
                      onClick={resetInput}
                    >
                      Resetten
                    </button>
                  </Col>

                  <Col md={8}>
                    <h4 className="grid-title">
                      {" "}
                      <BsFillPersonLinesFill
                        size={20}
                        style={{ marginRight: "0.3rem" }}
                      />
                      Resultaten
                    </h4>
                    <h6>
                      {candidatesResults.length} resultaten voor uw
                      zoekopdracht.
                    </h6>

                    <div>
                      <Table data={candidatesResults} columns={columns} />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
