import React from "react"
import "./AlphabetView.css"
import { Row, Col } from "react-bootstrap"
import StarRating from "../../../components/StarRating/StarRating"

export default function AlphabetView({ skillGroupsArray, role }) {
  return (
    <Row className="skill-groups">
      {skillGroupsArray.map((skillGroup, index) => {
        return (
          <Col
            key={index}
            className="skill-group"
            lg={skillGroup.skills.length > 6 ? 8 : 4}
          >
            <h6 className="skill-group-name">{skillGroup.group}</h6>
            <Row>
              {skillGroup.skills.map((skill, index) => {
                return (
                  <Col key={index} lg={skillGroup.skills.length > 6 ? 6 : 12}>
                    <div className="skill-group-skill-wrapper">
                      <div className="skill-group-skill">{skill.name}</div>
                      <div
                        className="skill-group-skill-starRate"
                        style={{ display: "none" }}
                      >
                        <StarRating role={role} />
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}
