import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { selectExpVariant } from "../../../store/user/selector"
import { apiPost } from "../../../utils/apiPostWrapper"
import SignUp from "../SignUp/SignUp"

const Home = ({ api }) => {
  const tempExpVariant = useSelector(selectExpVariant)

  //experiments from storage or redux store
  const experiment =
    (tempExpVariant && tempExpVariant) ||
    JSON.parse(sessionStorage.getItem("experimentVariant"))

  useEffect(() => {
    async function reportVariant() {
      if (experiment) {
        try {
          apiPost("/api/common/analytics/report", {
            exposures: {
              "landing-page": experiment["landing-page"],
            },
          })
        } catch (error) {
          if (error.response) {
            console.log(error.response.data)
          } else {
            console.log(error.message)
          }
        }
      }
    }
    reportVariant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <SignUp api={api} />{" "}
    </div>
  )
}

export default Home
