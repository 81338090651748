import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Switch, Route } from "react-router-dom"

import StudentHeader from "../../../components/Header/StudentHeader"
import StudentStatBar from "../../../components/StatBar/StudentStatBar"
import Profile from "../../Student/Profile/Profile"
import CoreTasks2012 from "../../Student/CoreTasks/CoreTasks2012"
import WorkProcesses from "../../Student/WorkProcesses/WorkProcesses"
import Competences from "../../Student/Competences/Competences"
import Skills from "../../Student/Skills/Skills"
import Scorecard from "../../Student/Scorecards/Scorecard"
import CoreTasks2016 from "../../Student/CoreTasks/CoreTasks2016"
import { trimSkillName } from "../../../utils/trimSkillName"
import {
  getCandidateStudies,
  getCandidateStudyDetail,
} from "../../../store/recruiter/action"
import {
  selectCandidateStudies,
  selectCandidateStudyDetail,
} from "../../../store/recruiter/selector"
import { selectSessionKey } from "../../../store/user/selector"

export default function CandidatePage() {
  const dispatch = useDispatch()
  const candidateStudies = useSelector(selectCandidateStudies)
  const { firstName, lastName, candidateId } = useParams()
  const sessionKey = useSelector(selectSessionKey)
  const candidateStudyDetail = useSelector(selectCandidateStudyDetail)
  function getEducationSystem(system) {
    const systems = {
      1: "2012",
      2: "2016",
    }
    return systems[system]
  }
  const educationSystem = getEducationSystem(
    candidateStudies?.[0].education_system_id
  )

  // get list of study
  useEffect(() => {
    dispatch(getCandidateStudies(sessionKey, candidateId))
  }, [sessionKey, candidateId, dispatch])

  //get latest study
  useEffect(() => {
    if ((sessionKey, candidateStudies && candidateStudies.length > 0)) {
      dispatch(
        getCandidateStudyDetail(
          sessionKey,
          candidateId,
          candidateStudies[0].profile_id,
          educationSystem
        )
      )
    }
  }, [sessionKey, dispatch, candidateStudies, candidateId, educationSystem])

  const pathUrlCandidateStudy = {
    profile: `/recruiter/candidate/${firstName}/${lastName}/${candidateId}/profile`,
    coreTasks: `/recruiter/candidate/${firstName}/${lastName}/${candidateId}/coretasks`,
    workprocesses: `/recruiter/candidate/${firstName}/${lastName}/${candidateId}/workprocesses`,
    competences: `/recruiter/candidate/${firstName}/${lastName}/${candidateId}/competences`,
    skills: `/recruiter/candidate/${firstName}/${lastName}/${candidateId}/skills`,
    scorecards: `/recruiter/candidate/${firstName}/${lastName}/${candidateId}/scorecards`,
  }

  //TRIM SKILL NAME within studyDetail
  const modifiedStudyDetail = candidateStudyDetail && {
    ...candidateStudyDetail,
    skills: candidateStudyDetail.skills.map((skill) => trimSkillName(skill)),
  }

  return (
    <div style={{ minHeight: "1500px" }}>
      {candidateStudyDetail && (
        <>
          <StudentHeader
            studyDetail={modifiedStudyDetail}
            name={`${firstName} ${lastName}`}
          />
          <StudentStatBar
            studyDetail={modifiedStudyDetail}
            pathUrl={pathUrlCandidateStudy}
          />
          {modifiedStudyDetail.education_system_id === 1 && (
            <Switch>
              <Route
                exact
                path={pathUrlCandidateStudy.profile}
                component={() => <Profile studyDetail={modifiedStudyDetail} />}
              />
              <Route
                exact
                path={pathUrlCandidateStudy.coreTasks}
                component={() => (
                  <CoreTasks2012 studyDetail={modifiedStudyDetail} />
                )}
              />
              <Route
                exact
                path={pathUrlCandidateStudy.workprocesses}
                component={() => (
                  <WorkProcesses
                    studyDetail={modifiedStudyDetail}
                    matrix="skill_matrix"
                  />
                )}
              />
              <Route
                exact
                path={pathUrlCandidateStudy.competences}
                component={() => (
                  <Competences studyDetail={modifiedStudyDetail} />
                )}
              />
              <Route
                exact
                path={pathUrlCandidateStudy.skills}
                component={() => <Skills studyDetail={modifiedStudyDetail} />}
              />

              <Route
                exact
                path={pathUrlCandidateStudy.scorecards}
                component={() => (
                  <Scorecard
                    studyDetail={modifiedStudyDetail}
                    matrix="skill_matrix"
                  />
                )}
              />
            </Switch>
          )}
          {candidateStudyDetail.education_system_id === 2 && (
            <>
              <Switch>
                <Route
                  exact
                  path={pathUrlCandidateStudy.profile}
                  component={() => (
                    <Profile studyDetail={modifiedStudyDetail} />
                  )}
                />
                <Route
                  exact
                  path={pathUrlCandidateStudy.coreTasks}
                  component={() => (
                    <CoreTasks2016 studyDetail={modifiedStudyDetail} />
                  )}
                />
                <Route
                  exact
                  path={pathUrlCandidateStudy.workprocesses}
                  component={() => (
                    <WorkProcesses
                      studyDetail={modifiedStudyDetail}
                      matrix="competence_matrix"
                    />
                  )}
                />
                <Route
                  exact
                  path={pathUrlCandidateStudy.competences}
                  component={() => (
                    <Competences studyDetail={modifiedStudyDetail} />
                  )}
                />
                <Route
                  exact
                  path={pathUrlCandidateStudy.skills}
                  component={() => <Skills studyDetail={modifiedStudyDetail} />}
                />
                <Route
                  exact
                  path={pathUrlCandidateStudy.scorecards}
                  component={() => (
                    <Scorecard
                      studyDetail={modifiedStudyDetail}
                      matrix="competence_matrix"
                    />
                  )}
                />
              </Switch>
            </>
          )}
        </>
      )}
    </div>
  )
}
