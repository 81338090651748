import React, { useState } from "react";
import StarRatings from "react-star-ratings";

export default function StarRating({ role }) {
  const [star, setStar] = useState(0);
  //change to action when there is endpoint
  const handleRateStar = (rating) => {
    setStar(rating);
  };
  return (
    <StarRatings
      rating={star}
      starRatedColor="#FFDF00"
      starHoverColor="#FFDF00"
      starDimension="25px"
      starSpacing="0"
      changeRating={role && role === "student" && handleRateStar}
      numberOfStars={5}
      name="skill-rating"
    />
  );
}
