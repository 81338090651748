import React from "react"

import "./ErrorPage.css"

const ErrorPage = () => {
  return (
    <div className="error-page container shadow-sm d-flex flex-column justify-content-center bg-white p-4">
      <h5>Sorry! Deze pagina is niet toegankelijk.</h5>{" "}
    </div>
  )
}

export default ErrorPage
