import React from "react"
import { Switch, Route, NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import "./UserSettingPage.css"
import { FaUser, FaKey, FaUserFriends } from "react-icons/fa"
import { MdDelete } from "react-icons/md"
import { Nav, Container } from "react-bootstrap"
import userImage from "../../../assets/user3.png"
import {
  selectPersonalDetails,
  selectSessionKey,
} from "../../../store/user/selector"
import General from "./SettingComponents/General"
import ChangePassword from "./SettingComponents/ChangePassword"
import DeleteAccount from "./SettingComponents/DeleteAccount"
import InviteFriend from "./SettingComponents/InviteFriend"

export default function UserSettingPage({ role }) {
  const personalDetails = useSelector(selectPersonalDetails)
  const sessionKey = useSelector(selectSessionKey)

  return (
    <Container className="light-style flex-grow-1 py-5">
      <h4 className="font-weight-bold py-2 mb-3">Account instellingen</h4>

      <div className="bg-white shadow rounded-lg">
        <div className="row px-3">
          <div className="col-md-4 px-0 border-right">
            <div className="p-4 text-center">
              <div className="img-circle text-center mb-4">
                <img src={userImage} alt="profile" className="shadow" />
              </div>
              <h4 className="text-center mb-3 font-weight-bold">
                {" "}
                {personalDetails?.first_name} {personalDetails?.last_name}
              </h4>
            </div>
            <Nav
              className="flex-column"
              variant="pills"
              defaultActiveKey={`/${role}/user-setting/general`}
            >
              <Nav.Item>
                <Nav.Link as={NavLink} to={`/${role}/user-setting/general`}>
                  <FaUser className="setting-icon mr-2" />
                  Algemeen
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  to={`/${role}/user-setting/change-password`}
                >
                  <FaKey className="setting-icon mr-2" />
                  Verander Wachtwoord
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link as={NavLink} to={`/${role}/user-setting/invite`}>
                  <FaUserFriends className="setting-icon mr-2" />
                  Nodig Een Vriend Uit
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  as={NavLink}
                  to={`/${role}/user-setting/delete-account`}
                >
                  <MdDelete className="setting-icon mr-2" />
                  Verwijder Account
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="col-md-8 p-3 p-md-5">
            <Switch>
              <Route exact path={`/${role}/user-setting`} />
              <Route
                exact
                path={`/${role}/user-setting/general`}
                render={({ props }) => (
                  <General
                    {...props}
                    sessionKey={sessionKey}
                    personalDetails={personalDetails && personalDetails}
                    role={role}
                  />
                )}
              />

              <Route
                exact
                path={`/${role}/user-setting/change-password`}
                render={({ props }) => (
                  <ChangePassword
                    {...props}
                    sessionKey={sessionKey}
                    personalDetails={personalDetails}
                  />
                )}
              />
              <Route
                exact
                path={`/${role}/user-setting/invite`}
                render={({ props }) => (
                  <InviteFriend
                    {...props}
                    sessionKey={sessionKey}
                    role={role}
                  />
                )}
              />
              <Route
                exact
                path={`/${role}/user-setting/delete-account`}
                render={({ props }) => (
                  <DeleteAccount {...props} sessionKey={sessionKey} />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    </Container>
  )
}
