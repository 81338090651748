import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import TitleRow from "../../../components/TitleRow/TitleRow";
import CardItem from "../../../components/Card/CardItem";
import { FaCogs } from "react-icons/fa";

export default function WorkProcesses({ studyDetail, matrix }) {
  const workProcesses = studyDetail.core_tasks
    .map((task) => task.work_processes)
    .reduce(function (arr, e) {
      return arr.concat(e);
    });
  return (
    <Container
      style={{
        padding: "20px",
        backgroundColor: "transparent",
      }}
    >
      <TitleRow title="Werkprocessen" />
      <Row>
        {studyDetail &&
          workProcesses.map((process, index) => {
            const competenceTotal =
              studyDetail &&
              studyDetail[matrix].filter(
                (i) => i.work_process_id === process.id
              );
            const uniqueCompetences = [
              ...new Map(
                competenceTotal.map((item) => [item.competence_id, item])
              ).values(),
            ];
            return (
              <Col sm="6" md="4" lg="3" key={index}>
                <CardItem
                  logo={FaCogs}
                  cardType="card-workprocesses"
                  cardName={process.name}
                  cardTitle="werkproces"
                  titleColor="rgb(140, 158, 20)"
                  items1={`competenties x${uniqueCompetences.length}`}
                />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}
