import { combineReducers } from "redux"

import user from "./user/reducer"
import appState from "./appState/reducer"
import recruiter from "./recruiter/reducer"
import student from "./student/reducer"

export default combineReducers({
  user,
  appState,
  recruiter,
  student,
})
