import React, { useEffect, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, Link, useLocation } from "react-router-dom"
import { Row, Col, Container } from "react-bootstrap"
import { FaEye } from "react-icons/fa"

import "./SignUp.css"

import image from "../../../assets/mortarboard.png"
import { apiPost } from "../../../utils/apiPostWrapper"
import { signUp } from "../../../store/user/action"
import {
  selectSessionKey,
  selectExpVariant,
} from "../../../store/user/selector"

export default function SignUp({ api }) {
  const role = api.charAt(0).toUpperCase() + api.slice(1)
  const dispatch = useDispatch()
  const sessionKey = useSelector(selectSessionKey)
  const tempExpVariant = useSelector(selectExpVariant)
  const history = useHistory()
  const [passwordShown, setPasswordShown] = useState(false)
  const [cityInputValue, setCityInputValue] = useState("")
  const [schoolInputValue, setSchoolInputValue] = useState("")
  const [profileInputValue, setProfileInputValue] = useState("")
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const inviteCode = searchParams.get("invitation")

  //experiments from storage or redux store
  const experiment =
    (tempExpVariant && tempExpVariant) ||
    JSON.parse(sessionStorage.getItem("experimentVariant"))

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      countryCode: "",
      schoolName: "",
      firstYear: "",
      lastYear: "",
      profile: "",
      password: "",
      confirmPassword: "",
      acceptTerms: false,
      recruiterVisible: false,
    },
    shouldUnregister: false,
  })
  const {
    firstName,
    lastName,
    email,
    city,
    schoolName,
    firstYear,
    lastYear,
    profile,
    password,
    recruiterVisible,
  } = watch()

  //convert string value to bolean
  const recruiterVisibleValue =
    typeof recruiterVisible === "string"
      ? // eslint-disable-next-line eqeqeq
        recruiterVisible == "true"
      : typeof recruiterVisible === "boolean"
      ? recruiterVisible
      : null

  const jobSearch = recruiterVisibleValue ? 2 : 0

  useEffect(() => {
    if (sessionKey !== null) {
      history.push(
        `/${api}/${
          api === "recruiter" ? "dashboard" : "student" ? "studies" : null
        }`
      )
    }
  }, [sessionKey, history, api])

  // request experiments
  useEffect(() => {
    async function reportVariant() {
      if (experiment) {
        try {
          apiPost("/api/common/analytics/report", {
            exposures: {
              "student-registration-work-and-recruiter":
                experiment["student-registration-work-and-recruiter"],
            },
          })
        } catch (error) {
          if (error.response) {
            console.log(error.response.data)
          } else {
            console.log(error.message)
          }
        }
      }
    }
    reportVariant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //load city options
  const loadCityOptions = async () => {
    if (cityInputValue.length > 2) {
      const response = await apiPost(`/api/common/search/city`, {
        search_term: cityInputValue,
      })
      return response.data.cities
    }
  }
  // load school options using API call
  const loadSchoolOptions = async () => {
    if (schoolInputValue.length > 2) {
      const response = await apiPost(`/api/common/search/school_name`, {
        search_term: schoolInputValue,
        country_code: "nl",
        lang_code: "nl",
      })
      return response.data.school_names
    }
  }

  // load profiles opitons using API call
  const loadProfileOptions = async () => {
    if (profileInputValue.length > 3) {
      const response = await apiPost(`/api/common/search/profile_revision`, {
        study_started_year: firstYear.value,
        search_term: profileInputValue,
        country_code: "nl",
        lang_code: "nl",
        school_id: schoolName.id,
      })

      return response.data.profiles
    }
  }

  const getProfileOptionLabel = (e) => {
    return (
      <>
        {" "}
        {e.name} - {e.education_name}{" "}
      </>
    )
  }
  const handleCityInputChange = (value) => {
    setCityInputValue(value)
  }
  const handleSchoolInputChange = (value) => {
    setSchoolInputValue(value)
  }
  const handleProfileInputChange = (value) => {
    setProfileInputValue(value)
  }
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  //study years
  const currentYear = new Date().getFullYear()
  const firstYearOptions = Array.from(
    { length: currentYear - 2012 + 1 },
    (_, i) => 2012 + i
  ).map((year) => {
    return { value: year, label: year }
  })
  const lastYearOptions = Array.from(
    { length: 15 },
    (_, i) => firstYear.value + i + 2
  ).map((year) => {
    return { value: year, label: year }
  })

  // style of select bar
  const selectStyles = {
    control: (base) => ({ ...base, height: 40, minheight: 40 }),
    valueContainer: (base) => ({ ...base, height: 40, padding: "0 6px" }),
    input: (base) => ({ ...base, margin: "0px" }),
    indicatorSeparator: () => ({ display: "none" }),
  }

  //submit form
  const submitForm = async () => {
    if (
      // FOR STUDENT
      api === "student"
    ) {
      dispatch(
        signUp(
          firstName.trim(),
          lastName.trim(),
          email,
          city.id,
          "nl",
          "nl",
          password,
          api,
          role,
          jobSearch,
          recruiterVisibleValue,
          schoolName,
          firstYear,
          profile,
          lastYear,
          inviteCode
        )
      )
      // for RECRUITER
    } else if (api === "recruiter") {
      dispatch(
        signUp(
          firstName.trim(),
          lastName.trim(),
          email,
          city.id,
          "nl",
          "nl",
          password,
          api,
          role
        )
      )
    }
  }

  return (
    <Container className="auth">
      <form className="auth-form py-3" onSubmit={handleSubmit(submitForm)}>
        <div className="text-center py-2">
          <img src={image} alt="" width="70px" />
        </div>
        <div className="form_title py-2">
          <h2>StudyCard Registratie</h2>
          (voor nu alleen mbo)
        </div>

        <div>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="row__value mt-3">
                <input
                  name="firstName"
                  {...register("firstName", {
                    required: "Deze invoer is vereist",
                    validate: (value) => {
                      return (
                        value.replace(/\s/g, "").length !== 0 ||
                        "Ongeldige invoer"
                      )
                    },
                  })}
                  className="form-control"
                  type="text"
                  id="firstName"
                  placeholder="Voornaam"
                />

                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="row__value mt-3">
                <input
                  name="lastName"
                  {...register("lastName", {
                    required: "Deze invoer is vereist",
                    validate: (value) => {
                      return (
                        value.replace(/\s/g, "").length !== 0 ||
                        "Ongeldige invoer"
                      )
                    },
                  })}
                  className="form-control"
                  type="text"
                  id="lastName"
                  placeholder="Achternaam"
                />
                <ErrorMessage
                  errors={errors}
                  name="lastName"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <div className="row__value mt-3">
                <input
                  name="email"
                  {...register("email", {
                    required: "Deze invoer is vereist",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Ongeldig e-mailadres",
                    },
                  })}
                  className="form-control"
                  type="text"
                  id="email"
                  placeholder="Email"
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="row__value mt-3">
                <Controller
                  control={control}
                  name="city"
                  rules={{
                    required: "Deze invoer is vereist",
                  }}
                  render={({ field }) => (
                    <AsyncSelect
                      {...field}
                      defaultOptions
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      loadOptions={loadCityOptions}
                      onInputChange={handleCityInputChange}
                      styles={selectStyles}
                      isClearable={true}
                      placeholder="Woonplaats"
                      noOptionsMessage={() => <>Geen opties</>}
                      loadingMessage={() => <> </>}
                    />
                  )}
                />

                <ErrorMessage
                  errors={errors}
                  name="city"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="row__value mt-3">
                <div className="password-wrapper ">
                  <input
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    {...register("password", {
                      required: "Deze invoer is vereist",
                      minLength: {
                        value: 8,
                        message: "Wachtwoord moet minimaal 8 tekens lang zijn",
                      },
                      pattern: {
                        value: /[^A-Za-z0-9]/,
                        message:
                          "Wachtwoord moet minstens 1 speciaal teken bevatten",
                      },
                      validate: (value) => {
                        const uniques = new Set(Array.from(value)).size

                        return (
                          uniques >= 5 ||
                          " Wachtwoord moet minimaal 5 verschillende karakters hebben"
                        )
                      },
                    })}
                    className="form-control "
                    id="password"
                    placeholder="Wachtwoord"
                  />
                  <FaEye
                    onClick={togglePasswordVisiblity}
                    className="eye-icon"
                  />
                </div>

                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p key={type} className="error__message">
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
            </Col>
          </Row>
          {api === "student" ? (
            <>
              {/* Study info */}
              <Row>
                <Col md={8} lg={8}>
                  <div className="row__value mt-3">
                    <Controller
                      control={control}
                      name="schoolName"
                      rules={{
                        required: "Deze invoer is vereist",
                      }}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          defaultOptions
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          loadOptions={loadSchoolOptions}
                          onInputChange={handleSchoolInputChange}
                          isClearable={true}
                          styles={selectStyles}
                          placeholder="School"
                          loadingMessage={() => <> </>}
                          noOptionsMessage={() => <>Geen opties</>}
                        />
                      )}
                    />

                    <ErrorMessage
                      errors={errors}
                      name="schoolName"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="error__message">
                            {message}
                          </p>
                        ))
                      }
                    />
                  </div>
                </Col>
                <Col md={4} lg={4}>
                  <div className="row__value mt-3">
                    <Controller
                      name="firstYear"
                      control={control}
                      rules={{
                        required: "Deze invoer is vereist",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={firstYearOptions}
                          placeholder="Start jaar"
                          styles={selectStyles}
                        />
                      )}
                    />
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="firstYear"
                    render={({ messages }) =>
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p key={type} className="error__message">
                          {message}
                        </p>
                      ))
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8} lg={8}>
                  <div className="row__value mt-3">
                    <Controller
                      name="profile"
                      control={control}
                      rules={{
                        required: "Deze invoer is vereist",
                      }}
                      render={({ field }) => (
                        <AsyncSelect
                          {...field}
                          defaultOptions
                          getOptionLabel={getProfileOptionLabel}
                          getOptionValue={(e) => e.id}
                          loadOptions={loadProfileOptions}
                          onInputChange={handleProfileInputChange}
                          styles={selectStyles}
                          isClearable={true}
                          isDisabled={firstYear !== "" ? false : true}
                          placeholder="Studie"
                          noOptionsMessage={() => <>Geen opties</>}
                          loadingMessage={() => <> </>}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="profile"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="error__message">
                            {message}
                          </p>
                        ))
                      }
                    />
                  </div>
                </Col>

                <Col md={4} lg={4}>
                  <div className="row__value mt-3">
                    <Controller
                      name="lastYear"
                      control={control}
                      rules={{
                        required: "Deze invoer is vereist",
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={lastYearOptions}
                          placeholder="Laatste jaar"
                          styles={selectStyles}
                          value={lastYear}
                          isDisabled={firstYear !== "" ? false : true}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="lastYear"
                      render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                          <p key={type} className="error__message">
                            {message}
                          </p>
                        ))
                      }
                    />
                  </div>
                </Col>
                {experiment &&
                  experiment["student-registration-work-and-recruiter"] ===
                    "joint-switch-ja-ik-zoek" && (
                    <Col md={12}>
                      <div
                        className="custom-control custom-switch mt-3"
                        style={{ fontSize: "14px", zIndex: 0 }}
                      >
                        <input
                          name="recruiterVisible"
                          {...register("recruiterVisible", {})}
                          className="custom-control-input cursor-pointer "
                          type="checkbox"
                          id="recruiterVisible"
                        />
                        <label
                          htmlFor="recruiterVisible"
                          className="custom-control-label"
                        >
                          Ik zoek een baan, dus baan/werk aanbieders mogen mij
                          benaderen.
                        </label>
                      </div>
                    </Col>
                  )}
                {experiment &&
                  experiment["student-registration-work-and-recruiter"] ===
                    "joint-binary-open" && (
                    <Col md={12} className="">
                      <div
                        className="border-bottom py-3"
                        style={{ fontSize: "14px", zIndex: 0 }}
                      >
                        <div className="form-check ml-2">
                          <input
                            {...register("recruiterVisible", {
                              required: "Deze invoer is vereist",
                            })}
                            value={true}
                            className="form-check-input cursor-pointer"
                            type="radio"
                            name="recruiterVisible"
                            id="recruiterContactYes"
                          />
                          <label
                            className="form-check-label pl-2"
                            htmlFor="recruiterContactYes"
                          >
                            Ik zoek een baan, dus baan/werk aanbieders mogen mij
                            benaderen.
                          </label>
                        </div>
                        <div className="form-check ml-2">
                          <input
                            {...register("recruiterVisible", {
                              required: "Deze invoer is vereist",
                            })}
                            value={false}
                            className="form-check-input cursor-pointer"
                            type="radio"
                            name="recruiterVisible"
                            id="recruiterContactNo"
                          />
                          <label
                            className="form-check-label pl-2"
                            htmlFor="recruiterContactNo"
                          >
                            Ik wil niet worden benaderd.
                          </label>
                        </div>
                      </div>

                      <ErrorMessage
                        errors={errors}
                        name="recruiterVisible"
                        render={({ messages }) =>
                          messages &&
                          Object.entries(messages).map(([type, message]) => (
                            <p key={type} className="error__message pt-0">
                              {message}
                            </p>
                          ))
                        }
                      />
                    </Col>
                  )}
              </Row>
            </>
          ) : null}
          <Row>
            <Col md={12}>
              <div
                className="custom-control custom-switch mt-3"
                style={{ fontSize: "14px", zIndex: 0 }}
              >
                <input
                  name="acceptTerms"
                  {...register("acceptTerms", {
                    validate: (value) =>
                      value === true ||
                      "De algemene voorwaarden moeten worden geaccepteerd om te kunnen registreren",
                  })}
                  className="custom-control-input cursor-pointer"
                  type="checkbox"
                  id="acceptTerms"
                />
                <label htmlFor="acceptTerms" className="custom-control-label">
                  {" "}
                  Ik ga akkoord met de{" "}
                  <Link to="/about/voorwaarden"> voorwaarden </Link>van
                  Scorecard. TLDR: Jouw persoonlijke data blijft jouw eigendom.
                </label>
              </div>
              <ErrorMessage
                errors={errors}
                name="acceptTerms"
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type} className="error__message pt-0">
                      {message}
                    </p>
                  ))
                }
              />
            </Col>
          </Row>
          <button className="button_primary" type="submit">
            {api === "student" ? `Maak mijn StudyCard aan!` : `Registreren`}
          </button>
        </div>
      </form>
      <br />
      <div style={{ textAlign: "center" }}>
        <p className="auth-text ">
          Al een account?{" "}
          <Link to={`/${api}/login`}>
            {" "}
            <button className="button_outline">Inloggen</button>
          </Link>
        </p>
      </div>
    </Container>
  )
}
