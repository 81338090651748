import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useHistory } from "react-router-dom"
import { BiArrowBack } from "react-icons/bi"

import {
  appDoneLoading,
  appLoading,
  showMessageWithTimeout,
} from "../../../store/appState/action"
import { resetPassword } from "../../../store/user/action"
import { selectSessionKey } from "../../../store/user/selector"
import { apiPost } from "../../../utils/apiPostWrapper"

const ForgotPassword = () => {
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const emailParams = searchParams.get("email")
  const resetKey = searchParams.get("reset_key")
  const roleParams = searchParams.get("role")
  const apiParams = searchParams.get("api")
  const sessionKey = useSelector(selectSessionKey)
  const dispatch = useDispatch()
  const [showEmailForm, setShowEmailForm] = useState(true)
  const [showEmailSent, setShowEmailSent] = useState(false)
  const [showSetPassword, setShowSetPassword] = useState(false)

  //for forgot password
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  //for reset password
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm()

  useEffect(() => {
    if (search !== "" && resetKey) {
      setShowEmailForm(false)
      setShowEmailSent(false)
      setShowSetPassword(true)
    } else {
      setShowSetPassword(false)
    }
  }, [search, resetKey])

  useEffect(() => {
    if (sessionKey !== null) {
      history.push(
        `/${apiParams}/${
          apiParams === "recruiter" ? "dashboard" : "student" ? "profile" : null
        }`
      )
    }
  }, [sessionKey, history, apiParams])

  // FORGOT password submit function
  const submitForgotPasswordForm = async (data) => {
    const { email } = data
    dispatch(appLoading())

    try {
      await apiPost(`/api/common/account/forgot_password`, {
        email: email,
        api: apiParams,
        role: roleParams,
        country_code: "nl",
        lang_code: "nl",
      })
      dispatch(appDoneLoading())
      setShowEmailForm(false)
      setShowEmailSent(true)
    } catch (error) {
      if (error.response) {
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 4000)
        )
      } else {
        dispatch(showMessageWithTimeout("danger", true, error.message, 4000))
      }
      dispatch(appDoneLoading())
    }
  }

  // RESET password submit function
  const submitResetPasswordForm = async (data) => {
    const { newPassword } = data
    dispatch(
      resetPassword(emailParams, resetKey, newPassword, apiParams, roleParams)
    )
  }

  return (
    <div
      className=" mx-auto my-3 p-3 border border-light bg-white shadow rounded-lg"
      style={{ maxWidth: "400px" }}
    >
      {showEmailForm && (
        <div id="forgot-pasword-email-form">
          <button
            type="button"
            className="d-flex align-items-center  py-1 px-2 border border-gray rounded-lg bg-none"
            onClick={() => history.goBack()}
          >
            <BiArrowBack className="mr-1" /> <span>Back</span>
          </button>

          <h6 className="font-weight-bold mt-3">Uw wachtwoord vergeten?</h6>
          <p>
            Voer uw e-mailadres in. We zullen u vervolgens een e-mail sturen met
            instructies voor het opnieuw instellen van uw wachtwoord
          </p>
          <form onSubmit={handleSubmit(submitForgotPasswordForm)}>
            <input
              {...register("email", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Ongeldig e-mailadres",
                },
              })}
              className="form-control"
              type="text"
              id="email"
              placeholder="Email..."
            />
            {errors.email?.type === "required" && (
              <p className="error__message">Deze invoer is vereis</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className="error__message">{errors.email.message}</p>
            )}{" "}
            <button type="submit" className="button_primary">
              {" "}
              Opnieuw instellen
            </button>
          </form>
        </div>
      )}

      {showEmailSent && (
        <div id="forgot-password-sent-email">
          <h6 className="font-weight-bold">
            We hebben je een e-mail gestuurd met een reset-link.
          </h6>
          <p>Met deze link kunt u een nieuw wachtwoord instellen.</p>
        </div>
      )}

      {showSetPassword && (
        <div id="forgot-pasword-email-form">
          <h6 className="font-weight-bold">Wachtwoord opnieuw instellen</h6>
          <p>
            Voer een nieuw wachtwoord in voor uw account: <i>{emailParams}</i>
          </p>
          <form onSubmit={handleSubmit2(submitResetPasswordForm)}>
            <input
              type={"password"}
              {...register2("newPassword", {
                required: true,
                minLength: {
                  value: 8,
                  message: "Wachtwoord moet minimaal 8 tekens lang zijn",
                },
                pattern: {
                  value: /[^A-Za-z0-9]/,
                },
                validate: (value) => {
                  const uniques = new Set(Array.from(value)).size

                  return uniques >= 5
                },
              })}
              className="form-control"
              id="password"
              placeholder="New password..."
            />

            {errors2.newPassword?.type === "pattern" && (
              <p className="error__message">
                Wachtwoord moet minstens 1 speciaal teken bevatten
              </p>
            )}
            {errors2.newPassword?.type === "validate" && (
              <p className="error__message">
                Wachtwoord moet minimaal 5 verschillende karakters hebben
              </p>
            )}
            {errors2.newPassword?.type === "minLength" && (
              <p className="error__message">{errors2.newPassword?.message}</p>
            )}
            {errors2.newPassword?.type === "required" && (
              <p className="error__message">Deze invoer is vereist</p>
            )}

            <button type="submit" className="button_primary">
              {" "}
              Opnieuw instellen
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

export default ForgotPassword
