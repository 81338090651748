import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import TitleRow from "../../../components/TitleRow/TitleRow";
import CardItem from "../../../components/Card/CardItem";
import { BsPuzzleFill } from "react-icons/bs";

export default function Competences({ studyDetail }) {
  const [chosenCompetence, setChosenCompetence] = useState(1);
  const competenceChildCount = (competence, skillTag) => {
    const children = studyDetail.skills
      .filter((s) =>
        studyDetail.skill_matrix
          .filter((i) => i.competence_id === competence.id)
          .map((m) => m.skill_id)
          .includes(s.id)
      )
      .filter((s) => s.skill_tags.map((tag) => tag.name).includes(skillTag));
    return children;
  };
  return (
    <Container
      style={{
        padding: "20px",
        backgroundColor: "transparent",
      }}
    >
      <TitleRow title="Competenties" />
      <Row>
        {studyDetail &&
          studyDetail.competences.map((competence, index) => {
            return (
              <Col sm="6" md="4" lg="3" key={index}>
                <CardItem
                  logo={BsPuzzleFill}
                  cardType="card-competences"
                  cardName={competence.name}
                  cardTitle="competentie"
                  titleColor="rgb(29,173,69)"
                  items1={
                    studyDetail.education_system_id === 1 &&
                    `vaardigheden x${
                      competenceChildCount(competence, "Vaardigheid").length
                    }`
                  }
                  items2={
                    studyDetail.education_system_id === 1 &&
                    `componenten x${
                      competenceChildCount(competence, "Component").length
                    }`
                  }
                  onClick={() => setChosenCompetence(competence)}
                  active={competence.id === chosenCompetence.id}
                />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
}
