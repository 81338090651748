import Nav from "react-bootstrap/Nav"
import { NavLink } from "react-router-dom"

export default function NavbarItem(props) {
  return (
    <Nav.Item
      style={{
        marginRight: "1rem",
        textDecoration: "none",
      }}
    >
      <Nav.Link as={NavLink} to={props.path} eventKey={props.eventKey}>
        {props.linkText}
      </Nav.Link>
    </Nav.Item>
  )
}
