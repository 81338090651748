import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useDispatch } from "react-redux"
import { FaEye } from "react-icons/fa"
import Modal from "react-bootstrap/Modal"

import { deleteAccount, logOut } from "../../../../store/user/action"

const DeleteAccount = ({ sessionKey }) => {
  const [passwordDeleteShown, setPasswordDeleteShown] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    if (!sessionKey) {
      dispatch(logOut())
    }
  }, [sessionKey, dispatch])

  const togglePasswordVisiblity = () => {
    setPasswordDeleteShown(!passwordDeleteShown)
  }

  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({ mode: "onChange", criteriaMode: "all" })

  const [emailDelete, passwordDelete] = watch(["emailDelete", "passwordDelete"])

  const handleDeleteAccount = () => {
    handleShowModal()
  }

  const handleConfirmDelete = () => {
    dispatch(deleteAccount(sessionKey, emailDelete, passwordDelete))
    handleCloseModal()
  }

  return (
    <form>
      <h4 className="mb-4">Verwijder Account</h4>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <h6 className="font-weight-bold">Email</h6>
            <input
              {...register("emailDelete", {
                required: "Deze invoer is vereist",
              })}
              type="text"
              className="form-control mb-1"
            />

            <ErrorMessage
              errors={errors}
              name="emailDelete"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <h6 className="font-weight-bold">Wachtwoord</h6>
            <div className="password-wrapper">
              <input
                type={passwordDeleteShown ? "text" : "password"}
                {...register("passwordDelete", {
                  required: "Deze invoer is vereist",
                })}
                className="form-control "
                id="passwordDelete"
              />
              <FaEye onClick={togglePasswordVisiblity} className="eye-icon" />
            </div>

            <ErrorMessage
              errors={errors}
              name="passwordDelete"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </div>

        <div className="px-3 mt-3">
          <button
            className="btn btn-outline-danger"
            type="button"
            onClick={handleSubmit(handleDeleteAccount)}
          >
            Verwijder Account
          </button>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verwijder Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Weet je zeker dat je dit account wilt verwijderen? Klik op Bevestigen
          om te verwijderen.
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-default" onClick={handleCloseModal}>
            Sluit
          </button>
          <button
            className="btn btn-outline-danger"
            type="submit"
            onClick={handleConfirmDelete}
          >
            Bevestig
          </button>
        </Modal.Footer>
      </Modal>
    </form>
  )
}

export default DeleteAccount
