import React from "react"
import { Container } from "react-bootstrap"
import { Markup } from "react-render-markup"
import TitleRow from "../../../components/TitleRow/TitleRow"

export default function Profile({ studyDetail }) {
  return (
    <>
      {studyDetail && (
        <Container
          style={{
            padding: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <TitleRow title="Profiel" />
          <div className="card-contents text-justify py-3 ">
            <h5>Uitleg</h5>
            <Markup markup={studyDetail.profile_description} />
          </div>
        </Container>
      )}
    </>
  )
}
