import React, { useState, useEffect, useRef } from "react"

import { Container, Row, Col } from "react-bootstrap"
import { Markup } from "react-render-markup"

import TitleRow from "../../../components/TitleRow/TitleRow"
import CardItem from "../../../components/Card/CardItem"
import { FaWrench, FaCogs } from "react-icons/fa"
import { BsPuzzleFill } from "react-icons/bs"
import { GiSkills } from "react-icons/gi"
// import { getMyStudyDetail, sortCardsAZ } from "../../store/user/action";
import { uniqueValues } from "../../../utils/uniqueValues"

export default function CoreTasks2012({ studyDetail }) {
  const [chosenCoreTask, setChosenCoreTask] = useState(1)
  const [chosenProcess, setChosenProcess] = useState(1)
  const [chosenCompetence, setChosenCompetence] = useState(1)
  const ref = useRef()
  const refCompetence = useRef()
  const refSkill = useRef()

  useEffect(() => {
    chosenCoreTask !== 1 && ref.current.scrollIntoView({ behavior: "smooth" })
    chosenProcess !== 1 &&
      refCompetence.current.scrollIntoView({ behavior: "smooth" })
    chosenCompetence !== 1 &&
      refSkill.current.scrollIntoView({ behavior: "smooth" })
  }, [chosenCoreTask, chosenProcess, chosenCompetence])

  const competenceChildCount = (competence, skillTag) => {
    const children = studyDetail.skills
      .filter((s) =>
        studyDetail.skill_matrix
          .filter(
            (i) =>
              i.work_process_id === chosenProcess.id &&
              i.competence_id === competence.id
          )
          .map((m) => m.skill_id)
          .includes(s.id)
      )
      .filter((s) => s.skill_tags.map((tag) => tag.name).includes(skillTag))
    return children
  }

  return (
    <Container
      style={{
        padding: "20px",
        backgroundColor: "transparent",
      }}
    >
      <TitleRow title="Kerntaken" />
      <Row>
        {studyDetail &&
          studyDetail.core_tasks.map((task, index) => {
            const workProcesses = task.work_processes.map(
              (process) => process.id
            )
            const matrixPerTask = studyDetail.skill_matrix.filter((i) =>
              workProcesses.includes(i.work_process_id)
            )

            const uniqueCompetences = uniqueValues(
              matrixPerTask,
              "competence_id"
            )
            const uniqueSkills = uniqueValues(matrixPerTask, "skill_id")

            return (
              <Col sm="6" md="4" lg="3" xl="3" key={index}>
                <CardItem
                  logo={FaWrench}
                  cardType="card-coretask"
                  cardName={task.name}
                  cardTitle="kerntaak"
                  titleColor="rgb(44,123,255)"
                  items1={`werkprocessen x${workProcesses.length}`}
                  items2={`competenties x${uniqueCompetences.length}`}
                  items3={`vaardigheden x${uniqueSkills.length}`}
                  cardId={task.id}
                  onClick={() => {
                    setChosenCoreTask(task)
                    setChosenProcess(1)
                  }}
                  active={task.id === chosenCoreTask.id}
                />
              </Col>
            )
          })}
        {chosenCoreTask !== 1 ? (
          <Col
            ref={ref}
            sm={12}
            style={{ fontSize: "14px" }}
            className="text-justify pt-2"
          >
            <h5 className="py-2 font-weight-bold">
              Kerntaak: {chosenCoreTask.name}
            </h5>
            <Markup markup={chosenCoreTask.description} />
          </Col>
        ) : null}
      </Row>
      {chosenCoreTask !== 1 ? (
        <>
          <TitleRow title="Werkprocessen" />
          <Row>
            {chosenCoreTask.work_processes.map((process, index) => {
              const matrixPerProcess =
                studyDetail &&
                studyDetail.skill_matrix.filter(
                  (i) => i.work_process_id === process.id
                )
              const uniqueComps = uniqueValues(
                matrixPerProcess,
                "competence_id"
              )
              return (
                <Col sm="6" md="4" lg="3" key={index}>
                  <CardItem
                    logo={FaCogs}
                    cardType="card-workprocesses"
                    cardName={process.name}
                    cardTitle="werkproces"
                    titleColor="rgb(140, 158, 20)"
                    items1={`competenties x${uniqueComps.length}`}
                    onClick={() => {
                      setChosenProcess({ ...process, matrix: uniqueComps })
                      setChosenCompetence(1)
                    }}
                    active={process.id === chosenProcess.id}
                  />
                </Col>
              )
            })}
          </Row>
        </>
      ) : null}
      {studyDetail && chosenProcess !== 1 ? (
        <>
          <TitleRow title="Competenties" />
          <Row ref={refCompetence}>
            {studyDetail.competences.filter((c) =>
              chosenProcess.matrix.map((m) => m.competence_id).includes(c.id)
            ).length === 0 ? (
              <Col style={{ margin: "1rem 0" }}>There is 0 Competence.</Col>
            ) : (
              studyDetail.competences
                .filter((c) =>
                  chosenProcess.matrix
                    .map((m) => m.competence_id)
                    .includes(c.id)
                )
                .map((competence, index) => {
                  const componentTotal = competenceChildCount(
                    competence,
                    "Component"
                  )
                  const vaardigheidTotal = competenceChildCount(
                    competence,
                    "Vaardigheid"
                  )
                  return (
                    <Col sm="6" md="4" lg="3" key={index}>
                      <CardItem
                        logo={BsPuzzleFill}
                        cardType="card-competences"
                        cardName={competence.name}
                        cardTitle="competentie"
                        titleColor="rgb(29,173,69)"
                        items1={`componenten x${componentTotal.length}`}
                        items2={`vaardigheid x${vaardigheidTotal.length}`}
                        onClick={() => setChosenCompetence(competence)}
                        active={competence.id === chosenCompetence.id}
                      />
                    </Col>
                  )
                })
            )}
          </Row>
        </>
      ) : null}

      {studyDetail && chosenProcess !== 1 && chosenCompetence !== 1 ? (
        <div ref={refSkill}>
          <>
            <TitleRow title="Componenten" />
            <Row>
              {competenceChildCount(chosenCompetence, "Component").length ===
              0 ? (
                <Col style={{ margin: "1rem 0" }}>There is 0 Component.</Col>
              ) : (
                competenceChildCount(chosenCompetence, "Component").map(
                  (skill, index) => {
                    return (
                      <Col sm="6" md="4" lg="3" key={index}>
                        <CardItem
                          logo={GiSkills}
                          cardType="card-skills"
                          cardTitle="component"
                          titleColor="rgb(153, 77, 153)"
                          cardName={skill.name}
                        />
                      </Col>
                    )
                  }
                )
              )}
            </Row>
          </>
          <>
            <TitleRow title="Vaardigheden" />

            <Row>
              {competenceChildCount(chosenCompetence, "Vaardigheid").length ===
              0 ? (
                <Col style={{ margin: "1rem 0" }}>There is 0 Vaardigheid.</Col>
              ) : (
                competenceChildCount(chosenCompetence, "Vaardigheid").map(
                  (skill, index) => {
                    return (
                      <Col sm="6" md="4" lg="3" key={index}>
                        <CardItem
                          logo={GiSkills}
                          cardType="card-skills"
                          cardTitle="vaardgiheid"
                          titleColor="rgb(153, 77, 153)"
                          cardName={skill.name}
                        />
                      </Col>
                    )
                  }
                )
              )}
            </Row>
          </>
        </div>
      ) : null}
    </Container>
  )
}
