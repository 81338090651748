import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Alert } from "react-bootstrap"

import { selectMessage } from "../store/appState/selector"
import { clearMessage } from "../store/appState/action"

export default function MessageBox() {
  const message = useSelector(selectMessage)
  const dispatch = useDispatch()
  const showMessage = message !== null
  if (!showMessage) return null

  return (
    <Alert
      show={showMessage}
      variant={message.variant}
      dismissible={message.dismissable}
      onClose={message.dismissable ? () => dispatch(clearMessage()) : null}
      style={{
        minWidth: "100%",
        marginBottom: "0",
        position: "fixed",
        zIndex: "5",
        top: "4.5rem",
      }}
    >
      {message && message.text.detail instanceof Array
        ? // Array.isArray(message.text.detail) === "true"
          message.text.detail.map((i, index) => {
            return <div key={index}>{i.msg}</div>
          })
        : typeof message.text.detail === "string"
        ? message.text.detail
        : !message.text.detail
        ? message.text
        : null}
    </Alert>
  )
}
