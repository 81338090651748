import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"

import "./StatBar.css"

export default function StudentStatBar({ studyDetail, pathUrl }) {
  let location = useLocation().pathname

  const { competences, skills, core_tasks } = studyDetail
  const workprocesses = core_tasks
    .map((task) => task.work_processes)
    .reduce(function (arr, e) {
      return arr.concat(e)
    })
  const coreTaskTotal = core_tasks.length
  const workProcessTotal = workprocesses.length
  const competencesTotal = competences.length
  const skillTotal = skills.length
  const scoreCards = coreTaskTotal + workProcessTotal + competencesTotal
  return (
    <Container
      style={{
        textAlign: "center",
        border: "1px solid #dbdbdb",
        backgroundColor: "white",
        padding: "10px",
        marginTop: "20px",
      }}
    >
      <Row className="mx-0">
        <Col xs={6} sm={4} lg={2} className="stat-wrapper">
          <Link to={pathUrl.profile}>
            <button
              className={
                location === pathUrl.profile
                  ? "stat-button-link-active"
                  : "stat-button-link"
              }
            >
              {" "}
              <h2>1</h2>
              PROFIEL
            </button>
          </Link>
        </Col>
        <Col xs={6} sm={4} lg={2} className="stat-wrapper">
          <Link to={pathUrl.coreTasks}>
            {" "}
            <button
              className={
                location === pathUrl.coreTasks
                  ? "stat-button-link-active"
                  : "stat-button-link"
              }
            >
              {" "}
              <h2>{coreTaskTotal}</h2>KERNTAKEN
            </button>
          </Link>
        </Col>
        <Col xs={6} sm={4} lg={2} className="stat-wrapper">
          <Link to={pathUrl.workprocesses}>
            <button
              className={
                location === pathUrl.workprocesses
                  ? "stat-button-link-active"
                  : "stat-button-link"
              }
            >
              {" "}
              <h2>{workProcessTotal}</h2>
              WERKPROCESSEN
            </button>
          </Link>
        </Col>
        <Col xs={6} sm={4} lg={2} className="stat-wrapper">
          <Link to={pathUrl.competences}>
            {" "}
            <button
              className={
                location === pathUrl.competences
                  ? "stat-button-link-active"
                  : "stat-button-link"
              }
            >
              {" "}
              <h2>{competencesTotal}</h2>
              COMPETENTIES
            </button>
          </Link>
        </Col>
        <Col xs={6} sm={4} lg={2} className="stat-wrapper">
          <Link to={pathUrl.skills}>
            <button
              className={
                location === pathUrl.skills
                  ? "stat-button-link-active"
                  : "stat-button-link"
              }
            >
              {" "}
              <h2>{skillTotal}</h2>
              VAARDIGHEDEN
            </button>
          </Link>
        </Col>
        <Col xs={6} sm={4} lg={2} className="stat-wrapper">
          <Link to={pathUrl.scorecards}>
            {" "}
            <button
              className={
                location === pathUrl.scorecards
                  ? "stat-button-link-active"
                  : "stat-button-link"
              }
            >
              {" "}
              <h2>{scoreCards}</h2>
              SCORECARDS
            </button>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}
