import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { FiPlus } from "react-icons/fi"

import JobCardMini from "./JobCardMini"
import { selectJobCards } from "../../../store/recruiter/selector"

const JobCardBoard = ({ role }) => {
  const jobCards = useSelector(selectJobCards)

  return (
    <div className="job-card-board container light-style flex-grow-1 pt-5">
      <h4 className="font-weight-bold py-2 mb-3">JobCard Bord</h4>
      <div className="bg-white shadow rounded-lg p-3 d-">
        <div className="cursor-pointer ">
          <Link to={`/${role}/create-job-card`}>
            <FiPlus size={40} />
          </Link>
        </div>
        {jobCards?.map((card) => (
          <JobCardMini jobCard={card} key={card.id} role="recruiter" />
        ))}
      </div>
    </div>
  )
}

export default JobCardBoard
