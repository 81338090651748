import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

import "./Footer.css"

import scorcardLogo from "../../assets/scorecard-logo.png"
import RBAC from "../../utils/RBAC"
import { selectSessionKey } from "../../store/user/selector"

const Footer = () => {
  const sessionKey = useSelector(selectSessionKey)

  return (
    <div className="footer">
      <img src={scorcardLogo} alt="logo" width="35px" />
      <ul className="footer-list">
        {!sessionKey && (
          <li className="footer-list-item">
            <Link to="/">Home</Link>
          </li>
        )}
        <RBAC allowedRoles={["recruiter"]}>
          <li className="footer-list-item">
            <Link to="/recruiter/dashboad">Home</Link>
          </li>
        </RBAC>
        <RBAC allowedRoles={["student"]}>
          <li className="footer-list-item">
            <Link to="/student/profile">Home</Link>
          </li>
        </RBAC>
        <li className="footer-list-item">
          <Link to="/about/informatie">Informatie</Link>
        </li>
        <li className="footer-list-item">
          <Link to="/about/faq">FAQ</Link>
        </li>
        <li className="footer-list-item">
          <Link to="/about/voorwaarden">Voorwaarden</Link>
        </li>
        <li className="footer-list-item">
          <Link to="/about/privacy-policy">Privacy Policy</Link>
        </li>
        <li className="footer-list-item">
          <Link to="/about/contact">Contact</Link>
        </li>
      </ul>
      <p className="copyright">© 2016-2021 Scorecard, All Rights Reserved</p>
    </div>
  )
}

export default Footer
