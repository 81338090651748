import React from "react"
import { Link } from "react-router-dom"

const JobCardMini = ({ jobCard, role }) => {
  return (
    <Link
      to={`/${role}/job-card/${jobCard.id}`}
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <div className="job-card-mini border p-2 my-3 rounded  cursor-pointer">
        <h5 className="job-card-mini__title font-weight-bold">
          {jobCard.jobTitle}
        </h5>
        <h6 className="job-card-mini__sub-title">{jobCard.company}</h6>
        <h6 className="job-card-mini__sub-title">{jobCard.location.name}</h6>
        <div className="job-card-mini__info">{jobCard.studyLevel} </div>
      </div>
    </Link>
  )
}

export default JobCardMini
