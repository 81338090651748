import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useHistory, Link } from "react-router-dom"

import image from "../../../assets/mortarboard.png"
import { logIn } from "../../../store/user/action"
import { selectSessionKey } from "../../../store/user/selector"

const LogIn = ({ api }) => {
  const role = api.charAt(0).toUpperCase() + api.slice(1)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ criteriaMode: "all" })

  const sessionKey = useSelector(selectSessionKey)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (sessionKey !== null) {
      history.push(
        `/${api}/${
          api === "recruiter" ? "dashboard" : "student" ? "studies" : null
        }`
      )
    }
  }, [sessionKey, history, api])

  const submitLogin = (data) => {
    const { email, password } = data
    dispatch(logIn(email, password, api, role, "nl", "nl"))
  }

  return (
    <div className="auth container" style={{ margin: "100px auto" }}>
      <form className="auth-form py-3">
        <div className="text-center py-2">
          <img src={image} alt="" width="70px" />
        </div>
        <div className="form_title py-2">
          <h2>StudyCard Login</h2>
        </div>

        <div className="pt-3">
          <div className="row__value  mb-3">
            <input
              {...register("email", {
                required: "Deze invoer is vereist",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Ongeldig e-mailadres",
                },
              })}
              className="form-control"
              type="text"
              id="email"
              placeholder="Email"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>

          <div className="row__value">
            <input
              {...register("password", {
                required: "Deze invoer is vereist",
              })}
              className="form-control"
              type="password"
              id="password"
              placeholder="Wachtwoord"
            />
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
          <button
            type="submit"
            className="button_primary"
            onClick={handleSubmit(submitLogin)}
          >
            Login
          </button>

          <p className="forgot-password text-center pt-2 mb-0">
            <Link to={`/forgot-password?api=${api}&role=${role}`}>
              Wachtwoord vergeten
            </Link>
          </p>
        </div>
      </form>
      <br />

      <div style={{ textAlign: "center" }}>
        <p className="auth-text">
          Geen StudyCard Account?
          <Link to={`/${api}/signup`}>
            {" "}
            <button className="button_outline">Maak er een!</button>
          </Link>
        </p>
      </div>
    </div>
  )
}
export default LogIn
