import { apiPost } from "../../utils/apiPostWrapper"
import {
  showMessageWithTimeout,
  appLoading,
  appDoneLoading,
} from "../appState/action"

//ENROLL STUDENT
export const ENROLL_SUCCESS = "ENROLL_SUCCESS"

const enrollSuccess = (enrollResponse) => {
  return {
    type: ENROLL_SUCCESS,
    payload: enrollResponse,
  }
}
//ENROLL A STUDY
export const enrollAStudy = (
  sessionKey,
  schoolName,
  firstYear,
  profile,
  lastYear
) => {
  return async (dispatch) => {
    dispatch(appLoading())
    try {
      const enrollResponse = await apiPost(`/api/student/study/enrol`, {
        session_key: sessionKey,
        school_id: schoolName.id,
        profile_id: profile.id,
        revision_id: profile.revision_id,
        study_started_year: firstYear.value,
        study_stopped_year: lastYear.value,
        diploma_obtained:
          lastYear.value > new Date().getFullYear() ? false : true,
        attained_nqf_level: profile.national_qualification_level_id,
      })
      dispatch(enrollSuccess(enrollResponse.data))
      dispatch(
        showMessageWithTimeout(
          "success",
          true,
          "Je hebt een studie succesvol toegevoegd.",
          5000
        )
      )
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log("error:", error.response.data)
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log("error:", error.message)
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// GET MY STUDIES OF LOGINED USER
export const GET_MY_STUDIES = "GET_MY_STUDIES"

const getMyStudiesSuccess = (myStudies) => {
  return {
    type: GET_MY_STUDIES,
    payload: myStudies,
  }
}

export const getMyStudies = (sessionKey) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(`/api/student/study/list_studies`, {
        session_key: sessionKey,
      })

      dispatch(getMyStudiesSuccess(response.data.studies))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// GET STUDY DETAIL  OF LOGINED USER
export const GET_A_STUDY_DETAIL = "GET_A_STUDY_DETAIL"

const getMyStudyDetailSuccess = (study) => {
  return {
    type: GET_A_STUDY_DETAIL,
    payload: study,
  }
}

export const getMyStudyDetail = (sessionKey, profileId, eduSystem) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    try {
      const response = await apiPost(
        `/api/student/mbo/study_details_mbo_${eduSystem}`,

        {
          session_key: sessionKey,
          profile_id: profileId,
        }
      )
      dispatch(getMyStudyDetailSuccess(response.data))
      dispatch(appDoneLoading())
    } catch (error) {
      if (error.response) {
        // console.log(error.response.data);
        dispatch(
          showMessageWithTimeout("danger", true, error.response.data, 5000)
        )
      } else {
        // console.log(error.message);
        dispatch(showMessageWithTimeout("danger", true, error.message, 5000))
      }
      dispatch(appDoneLoading())
    }
  }
}

// SORT CARDS A-Z
export const SORT_CARDS = "SORT_CARDS"

const sortCardsSuccess = (cards) => {
  return {
    type: SORT_CARDS,
    payload: cards,
  }
}
export const sortCardsAZ = (cards) => {
  return async (dispatch, getState) => {
    dispatch(appLoading())
    dispatch(sortCardsSuccess(cards))
    dispatch(appDoneLoading())
  }
}
