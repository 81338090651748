import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { FaEye } from "react-icons/fa"

import { changePassword } from "../../../../store/user/action"

const ChangePassword = ({ sessionKey, personalDetails }) => {
  const dispatch = useDispatch()
  const email = personalDetails?.email
  const [passwordShown, setPasswordShown] = useState(false)
  const [newPasswordShown, setNewPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }
  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true)
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" })
  const handleChangePassword = (data) => {
    const { password, newPassword } = data
    dispatch(changePassword(sessionKey, email, password, newPassword))
    reset({ password: "", newPassword: "" })
  }
  return (
    <>
      <h4 className="mb-4">Verander Wachtwoord</h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <h6 className="font-weight-bold">Huidig ​​wachtwoord</h6>
            <div className="password-wrapper">
              <input
                name="password"
                type={passwordShown ? "text" : "password"}
                {...register("password", {
                  required: true,
                })}
                className="form-control"
                id="password"
              />
              <FaEye onClick={togglePasswordVisiblity} className="eye-icon " />
            </div>
            {errors.password?.type === "required" && (
              <p className="error__message">Deze invoer is vereist</p>
            )}
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <h6 className="font-weight-bold">Nieuw ​​wachtwoord</h6>
            <div className="password-wrapper">
              <input
                name="newPassword"
                type={newPasswordShown ? "text" : "password"}
                {...register("newPassword", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Wachtwoord moet minimaal 8 tekens bevatten",
                  },
                  pattern: {
                    value: /[^A-Za-z0-9]/,
                  },
                  validate: (value) => {
                    const uniques = new Set(Array.from(value)).size

                    return uniques >= 5
                  },
                })}
                className="form-control mb-1"
                id="newPassword"
              />
              <FaEye
                onClick={toggleNewPasswordVisiblity}
                className="eye-icon"
              />
            </div>
            {errors.newPassword?.type === "pattern" && (
              <p className="error__message">
                Wachtwoord moet minimaal 1 speciaal teken hebben
              </p>
            )}
            {errors.newPassword?.type === "validate" && (
              <p className="error__message">
                Wachtwoord moet minimaal 5 verschillende karakters hebben
              </p>
            )}
            {errors.newPassword?.type === "minLength" && (
              <p className="error__message">{errors.newPassword?.message}</p>
            )}
            {errors.newPassword?.type === "required" && (
              <p className="error__message">Deze invoer is vereist</p>
            )}
          </div>
        </div>

        <div className="px-3 mt-3">
          <button
            type="submit"
            className="btn btn-save"
            onClick={handleSubmit(handleChangePassword)}
          >
            Wijzigingen Opslaan
          </button>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
