import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectSessionKey } from "../../../store/user/selector"
import { selectMyStudies } from "../../../store/student/selector"

const StudiesRoute = ({ path }) => {
  const sessionKey = useSelector(selectSessionKey)
  const myStudies = useSelector(selectMyStudies)
  const history = useHistory()

  //Show the most recent study when user first log in
  const educationId = myStudies?.[0]?.education_system_id
  const profileId = myStudies?.[0]?.profile_id

  useEffect(() => {
    if (sessionKey && myStudies?.length > 0) {
      history.push(`${path}/studies/${profileId}/${educationId}/profile`)
    }
  }, [history, sessionKey, myStudies, profileId, educationId, path])
  return <></>
}

export default StudiesRoute
