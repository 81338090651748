export const apiURL = "https://scorecard-demo.azurewebsites.net"

export const rolesAndRoutes = {
  profile: {
    route: "/student/studies/:profileId/:eduSystem/profile",
    roles: ["student"],
  },
  coreTasks: {
    route: "/student/studies/:profileId/:eduSystem/coretasks",
    roles: ["student"],
  },
  workProcesses: {
    route: "/student/studies/:profileId/:eduSystem/workprocesses",
    roles: ["student"],
  },
  competences: {
    route: "/student/studies/:profileId/:eduSystem/competences",
    roles: ["student"],
  },
  skills: {
    route: "/student/studies/:profileId/:eduSystem/skills",
    roles: ["student"],
  },
  scorecards: {
    route: "/student/studies/:profileId/:eduSystem/scorecards",
    roles: ["student"],
  },
  dashBoard: {
    route: "/recruiter/dashboard",
    roles: ["recruiter"],
  },
}
