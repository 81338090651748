import React, { useState } from "react"

import { Container, Row, Col } from "react-bootstrap"

import "./Skills.css"

import TitleRow from "../../../components/TitleRow/TitleRow"
import CardItem from "../../../components/Card/CardItem"
import AlphabetView from "./AlphabetView"
import { GiSkills } from "react-icons/gi"

export default function Skills({ studyDetail, role }) {
  const [alphabetView, setAlphabetView] = useState(false)
  const [cardView, setCardView] = useState(true)

  const skillGroups = studyDetail.skills.reduce((r, e) => {
    const key = e.name[0]

    if (/[a-zA-Z]/.test(key)) {
      if (!r[key]) r[key] = []
      r[key].push(e)
    } else if (!/[a-zA-Z]/.test(key)) {
      const rightKey = e.name.replace(/^[^a-z]+/gi, "").substr(0, 1)
      if (!r[rightKey]) r[rightKey] = []
      r[rightKey].push(e)
    }
    return r
  }, {})
  const skillGroupsArray = Object.entries(skillGroups)
    .map((item) => {
      return {
        group: item[0],
        skills: item[1],
      }
    })
    .sort((a, b) => a.group.localeCompare(b.group))

  return (
    <Container
      style={{
        padding: "20px",
        backgroundColor: "transparent",
      }}
    >
      <div className="view-buttons">
        <div
          className={cardView ? "view-button-active" : "view-button"}
          onClick={() => {
            setCardView(true)
            setAlphabetView(false)
          }}
        >
          Kaartlijst
        </div>
        <div
          className={alphabetView ? "view-button-active" : "view-button"}
          onClick={() => {
            setCardView(false)
            setAlphabetView(true)
          }}
        >
          A-Z
        </div>
      </div>
      {alphabetView && (
        <AlphabetView skillGroupsArray={skillGroupsArray} role={role} />
      )}

      {cardView && (
        <>
          {studyDetail.education_system_id === 1 && (
            <>
              <TitleRow title="Componenten" />
              <Row>
                {studyDetail.skills
                  .filter((s) =>
                    s.skill_tags.map((tag) => tag.name).includes("Component")
                  )
                  .map((skill, index) => (
                    <Col sm="6" md="4" lg="3" key={index}>
                      <CardItem
                        logo={GiSkills}
                        cardType="card-skills"
                        cardName={skill.name}
                        cardTitle="component"
                        titleColor="rgb(153, 77, 153)"
                        role={role}
                      />
                    </Col>
                  ))}
              </Row>
              <TitleRow title="Vaardigheden" />
              <Row>
                {studyDetail.skills
                  .filter((s) =>
                    s.skill_tags.map((tag) => tag.name).includes("Vaardigheid")
                  )
                  .map((skill, index) => (
                    <Col sm="6" md="4" lg="3" key={index}>
                      <CardItem
                        logo={GiSkills}
                        cardType="card-skills"
                        cardName={skill.name}
                        cardTitle="vaardigheid"
                        titleColor="rgb(153, 77, 153)"
                        role={role}
                      />
                    </Col>
                  ))}
              </Row>
            </>
          )}
          {studyDetail.education_system_id === 2 && (
            <>
              <TitleRow title="Vaardigheden" />
              <Row>
                {studyDetail.skills.map((skill, index) => (
                  <Col sm="6" md="4" lg="3" key={index}>
                    <CardItem
                      logo={GiSkills}
                      cardType="card-skills"
                      cardName={skill.name}
                      cardTitle="vaardigheid"
                      titleColor="rgb(153, 77, 153)"
                      role={role}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  )
}
