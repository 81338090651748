function replaceName(skill, string) {
  return skill.replace(string, "");
}
function upperCase(string) {
  return string[0].toUpperCase() + string.slice(1);
}
const toBeReplacedArray = ["Kan een ", "Kan  ", "Kan ", "kan ", "Heeft "];
export function trimSkillName(skill) {
  const match = toBeReplacedArray.find((word) => skill.name.startsWith(word));
  return match
    ? { ...skill, name: upperCase(replaceName(skill.name, match)) }
    : { ...skill, name: upperCase(skill.name) };
}
