import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import "./StatBar.css"

export default function RecruiterStatBar(props) {
  return (
    <Container
      style={{
        textAlign: "center",
        border: "1px solid #dbdbdb",
        backgroundColor: "white",
        padding: "10px",
        marginTop: "20px",
      }}
    >
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col xs={6} sm={2} lg={2} md={2} className="stat-wrapper">
          <h2>10K</h2>
          PROFIELEN
        </Col>
        <Col xs={6} sm={2} lg={2} md={2} className="stat-wrapper">
          <h2>3K</h2> STUDIES
        </Col>
        <Col xs={6} sm={2} lg={2} md={2} className="stat-wrapper">
          <h2>5K</h2>
          SKILLS
        </Col>
        <Col xs={6} sm={2} lg={2} md={2} className="stat-wrapper">
          <h2>6K</h2>
          COMPETENTIES
        </Col>
        <Col xs={6} sm={2} lg={2} md={2} className="stat-wrapper">
          <h2>10K</h2>
          STUDYCARDS
        </Col>
        <Col xs={6} sm={3} lg={3} md={3}></Col>
      </Row>
    </Container>
  )
}
