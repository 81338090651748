import React, { useEffect } from "react"
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import RouteWrapper from "../../utils/RouteWrapper"
import DashBoard from "../Recruiter/DashBoard/DashBoard"
import UserSettingPage from "../Common/UserSetting/UserSettingPage"
import CandidatePage from "../Recruiter/Candidate/CandidatePage"
import CreateJobCard from "../Recruiter/CreateJobCard/CreateJobCard"
import LogIn from "../Common/LogIn/LogIn"
import SignUp from "../Common/SignUp/SignUp"
import { rolesAndRoutes } from "../../config/constant"
import { selectSessionKey } from "../../store/user/selector"
import JobCardBoard from "../Recruiter/JobCard/JobCardBoard"
import JobCardDetail from "../Recruiter/JobCard/JobCardDetail"

function Recruiter({ path }) {
  const sessionKey = useSelector(selectSessionKey)
  const history = useHistory()

  useEffect(() => {
    if (sessionKey === null) {
      history.push(`${path}`)
    }
  }, [sessionKey, history, path])
  return (
    <Switch>
      <RouteWrapper
        roles={["recruiter"]}
        path={`${path}/user-setting`}
        Component={() => <UserSettingPage role="recruiter" />}
      />
      <RouteWrapper
        exact
        roles={["recruiter"]}
        path={`${path}/create-job-card`}
        Component={() => <CreateJobCard role="recruiter" />}
      />

      <RouteWrapper
        exact
        roles={["recruiter"]}
        path={`${path}/job-cards-overview`}
        Component={() => <JobCardBoard role="recruiter" />}
      />

      <RouteWrapper
        exact
        roles={["recruiter"]}
        path={`${path}/job-card/:id`}
        Component={() => <JobCardDetail role="recruiter" />}
      />

      <RouteWrapper
        exact
        path={rolesAndRoutes.dashBoard.route}
        roles={rolesAndRoutes.dashBoard.roles}
        Component={DashBoard}
      />
      <RouteWrapper
        roles={["recruiter"]}
        path={`${path}/candidate/:firstName/:lastName/:candidateId`}
        Component={CandidatePage}
      />
    </Switch>
  )
}

export default function RecruiterWrapper() {
  let { path } = useRouteMatch()
  const sessionKey = useSelector(selectSessionKey)

  return (
    <div style={{ paddingBottom: "10rem", marginTop: "2.5rem" }}>
      <Switch>
        <Route exact path={path}>
          {sessionKey ? (
            <Redirect to={`${path}/dashboard`} />
          ) : (
            <Redirect to={`${path}/login`} />
          )}
        </Route>
        <Route
          exact
          path={`${path}/login`}
          render={({ props }) => <LogIn {...props} api="recruiter" />}
        />
        <Route
          exact
          path={`${path}/signup`}
          render={({ props }) => <SignUp {...props} api="recruiter" />}
        />

        {/* group routes that require sessionKey for recruiter  */}
        <Route>
          <Recruiter path={path} />
        </Route>
      </Switch>
    </div>
  )
}
