import React, { useState, useEffect } from "react"
import { apiPost } from "../../../../utils/apiPostWrapper"

const ChangePassword = ({ sessionKey, role }) => {
  const [inviteCode, setInviteCode] = useState("")
  const [visible, setVisible] = useState(false)

  const getInviteCode = async () => {
    try {
      const response = await apiPost(`/api/common/account/add_invitation`, {
        session_key: sessionKey,
      })
      setInviteCode(response.data.invitation_key)
    } catch (error) {
      console.log("error", error.response.data)
    }
  }

  const invitationLink = `https://demo.scorecard.school/${role}/signup?invitation=${inviteCode}`
  const handleCopy = async () => {
    navigator.clipboard.writeText(invitationLink)
    setVisible(true)
    setTimeout(() => {
      setVisible(false)
    }, 2000)
  }

  useEffect(() => {
    sessionKey && getInviteCode()
    // eslint-disable-next-line
  }, [sessionKey])

  return (
    <div>
      <h4 className="mb-4">Nodig Een Vriend Uit</h4>

      <p>
        Nodig je vrienden uit om zich aan te melden voor StudyCard met de
        volgende link
      </p>

      <input
        type="text"
        readOnly
        value={invitationLink}
        className="invite-link w-100"
      />
      <div className="d-flex flex-row align-items-center">
        <button className="button_outline my-3" onClick={handleCopy}>
          Kopieer Link
        </button>
        {visible && (
          <div className="px-2">
            <i>Gekopieerd!</i>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChangePassword
