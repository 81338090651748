import React, { useState, useMemo, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { useDispatch } from "react-redux"
import moment from "moment"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import Modal from "react-bootstrap/Modal"
import { FaEye } from "react-icons/fa"

import { apiPost } from "../../../../utils/apiPostWrapper"
import {
  changePersonalDetails,
  changePersonalSettings,
} from "../../../../store/user/action"

const General = ({ sessionKey, personalDetails, role }) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)
  const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)
  const [cityOptions, setCityOptions] = useState([])
  const [isCityLoading, setIsCityLoading] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordConfirmShown(!passwordConfirmShown)
  }

  //Check if user needs to enter passwords based on which fields have been updated
  const handleClickUpdate = () => {
    const importantFields = ["firstName", "lastName", "newEmail", "dateOfBirth"]
    if (
      Object.keys(changedData).some((field) => importantFields.includes(field))
    ) {
      handleShowModal()
    } else {
      handleChangeWithoutPassword()
    }
  }

  //Set original data fetched from api to defaultValues of form
  const originalData = useMemo(() => {
    return {
      firstName: personalDetails?.first_name,
      lastName: personalDetails?.last_name,
      newEmail: personalDetails?.email,
      dateOfBirth: personalDetails?.date_of_birth,
      city: [
        {
          id: personalDetails?.work_city_id,
          name: personalDetails?.work_city_name,
        },
      ],
      jobSearch: personalDetails?.user_job_search_state_id,
      recruiterVisible: personalDetails?.recruiter_visible_default,
      password: "",
    }
  }, [personalDetails])

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    reset,
    getValues,
  } = useForm({
    defaultValues: originalData,
    criteriaMode: "all",
    mode: "onChange",
  })

  //Watch for form inputs
  const currentFormData = watch()
  const {
    firstName,
    lastName,
    newEmail,
    dateOfBirth,
    city,
    jobSearch,
    recruiterVisible,
    password,
  } = currentFormData

  //Persist input in first render
  useEffect(() => {
    reset({
      firstName: personalDetails?.first_name,
      lastName: personalDetails?.last_name,
      newEmail: personalDetails?.email,
      dateOfBirth: personalDetails?.date_of_birth,
      city: [
        {
          id: personalDetails?.work_city_id,
          name: personalDetails?.work_city_name,
        },
      ],
      password: "",
      jobSearch: personalDetails?.user_job_search_state_id,
      recruiterVisible: personalDetails?.recruiter_visible_default,
    })
  }, [personalDetails, reset])

  // GET CITIES
  const loadCityOptions = async (searchTerm) => {
    try {
      setIsCityLoading(true)
      const citiesResponse = await apiPost(`/api/common/search/city`, {
        search_term: searchTerm,
      })
      setCityOptions(citiesResponse.data.cities)
      setIsCityLoading(false)
    } catch (error) {
      console.log("error", error)
      setIsCityLoading(false)
    }
  }

  // Watch for which fields have been updated
  const changedData = Object.keys(currentFormData).reduce((acc, field) => {
    if (currentFormData[field] !== originalData[field]) {
      acc[field] = currentFormData[field]
    }
    return acc
  }, {})

  //Submit function for updated fields that require password
  const handleChangeWithPassword = () => {
    const inputTosend = {
      email: personalDetails?.email,
      password: password,
      session_key: sessionKey,
      new_email: newEmail,
      new_work_city_id: city.length > 0 ? city[0].id : null,
      new_date_of_birth:
        moment(dateOfBirth).format("YYYY-MM-DD") !== "Invalid date"
          ? dateOfBirth
          : null,
      new_first_name: firstName,
      new_last_name: lastName,
      new_recruiter_visible_default:
        role === "student" ? recruiterVisible : false,
      new_user_job_search_state_id: role === "student" ? jobSearch : 0,
    }
    dispatch(changePersonalDetails(inputTosend))

    reset({ ...getValues(), password: "" })
    handleCloseModal()
  }

  //Submit function for updated fields that DOES NOT require password
  const handleChangeWithoutPassword = () => {
    const inputTosend = {
      session_key: sessionKey,
      new_work_city_id: city.length > 0 ? city[0].id : null,
      new_recruiter_visible_default:
        role === "student" ? recruiterVisible : false,
      new_user_job_search_state_id: role === "student" ? jobSearch : 0,
    }
    dispatch(changePersonalSettings(inputTosend))
    handleCloseModal()
  }

  return (
    <>
      <h4 className="mb-4">Algemeen</h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <h6 className="font-weight-bold">Voornaam</h6>
            <input
              className="form-control"
              name="firstName"
              type="text"
              id="firstName"
              defaultValue={personalDetails?.first_name}
              {...register("firstName", {
                required: "Deze invoer is vereist",
              })}
            />

            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <h6 className="font-weight-bold"> Achternaam</h6>
            <input
              className="form-control"
              type="text"
              name="lastName"
              id="lastName"
              defaultValue={personalDetails?.last_name}
              {...register("lastName", {
                required: "Deze invoer is vereist",
              })}
            />
            {errors.lastName?.type === "required" && (
              <p className="error__message">Deze invoer is vereist</p>
            )}
            <ErrorMessage
              errors={errors}
              name="lastName"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <h6 className="font-weight-bold">Email</h6>
            <input
              className="form-control"
              type="text"
              defaultValue={personalDetails?.email}
              {...register("newEmail", {
                required: "Deze invoer is vereist",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Ongeldig e-mailadres",
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="newEmail"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <h6 className="font-weight-bold">Geboortedatum</h6>
            <input
              className="form-control"
              type="date"
              name="dateOfBirth"
              data-date-format="YYYY/MM/DD"
              placeholder="YYYY/MM/DD"
              defaultValue={personalDetails?.date_of_birth ?? null}
              {...register("dateOfBirth")}
            />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="form-group">
            <h6 className="font-weight-bold">Locatie</h6>

            <Controller
              control={control}
              name="city"
              rules={{
                required: "Deze invoer is vereist",
              }}
              render={({ field }) => (
                <AsyncTypeahead
                  {...field}
                  placeholder={city?.[0]?.name}
                  defaultInputValue={originalData.city?.[0].name}
                  labelKey={(option) => `${option.name}`}
                  id="User city"
                  isLoading={isCityLoading}
                  minLength={2}
                  onSearch={loadCityOptions}
                  options={cityOptions}
                  bsSize="sm"
                  promptText="Kies en stad..."
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="city"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </div>
        {role === "student" && (
          <>
            <div className="col-md-6">
              <div className="form-group">
                <h6 className="font-weight-bold">Ik zoek werk/baan</h6>

                <select
                  {...register("jobSearch", {
                    required: true,
                  })}
                  id="jobSearch"
                  className="form-control"
                  value={jobSearch}
                >
                  <option value={2}> Ik ben actief op zoek!</option>
                  <option value={1}> Ik sta open voor aanbiedingen</option>
                  <option value={0}> Nee</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="custom-control custom-switch "
                style={{ zIndex: 0 }}
              >
                <input
                  name="recruiterVisible"
                  {...register("recruiterVisible", {})}
                  className="custom-control-input cursor-pointer "
                  type="checkbox"
                  id="recruiterVisible"
                />
                <label
                  htmlFor="recruiterVisible"
                  className="custom-control-label"
                >
                  Toon mijn profiel aan werkaanbieders
                </label>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row px-3 mt-3">
        <button
          type="submit"
          className="btn btn-save"
          onClick={handleClickUpdate}
        >
          Wijzigingen Opslaan
        </button>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Algemene details bijwerken</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="form-label">
              Voer uw wachtwoord in om wijzigingen te bevestigen.
            </label>
            <div className="password-wrapper">
              <input
                type={passwordConfirmShown ? "text" : "password"}
                {...register("password", {
                  required: "Deze invoer is vereist",
                })}
                className="form-control "
                id="password"
              />
              <FaEye onClick={togglePasswordVisiblity} className="eye-icon" />
            </div>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ messages }) =>
                messages &&
                Object.entries(messages).map(([type, message]) => (
                  <p key={type} className="error__message">
                    {message}
                  </p>
                ))
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-default"
            onClick={() => {
              handleCloseModal()
              reset({ ...getValues(), password: "" })
            }}
          >
            Sluit
          </button>
          <button
            className="btn btn-save"
            onClick={handleSubmit(handleChangeWithPassword)}
          >
            Bevestig
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default General
