import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import TitleRow from "../../../components/TitleRow/TitleRow";
import CardItem from "../../../components/Card/CardItem";
import WorkProcesses from "../WorkProcesses/WorkProcesses";
import Competences from "../Competences/Competences";

import { FaWrench } from "react-icons/fa";
import { uniqueValues } from "../../../utils/uniqueValues";

export default function Scorecard({ studyDetail, matrix }) {
  return (
    <Container
      style={{
        padding: "0",
        backgroundColor: "transparent",
      }}
    >
      <div
        style={{
          padding: "20px ",
        }}
      >
        <TitleRow title="Kerntaken" />
        <Row>
          {studyDetail &&
            studyDetail.core_tasks.map((task, index) => {
              const workProcesses = task.work_processes.map(
                (process) => process.id
              );
              const matrixPerTask = studyDetail[matrix].filter((i) =>
                workProcesses.includes(i.work_process_id)
              );
              const uniqueCompetences = uniqueValues(
                matrixPerTask,
                "competence_id"
              );
              const uniqueSkills =
                matrix === "competence_matrix"
                  ? studyDetail.skill_matrix.filter(
                      (i) => i.core_task_id === task.id
                    )
                  : uniqueValues(matrixPerTask, "skill_id");
              return (
                <Col sm="6" md="4" lg="3" key={index}>
                  <CardItem
                    logo={FaWrench}
                    cardType="card-coretask"
                    cardName={task.name}
                    cardTitle="kerntaak"
                    titleColor="rgb(44,123,255)"
                    items1={`workprocessen x${workProcesses.length}`}
                    items2={`competenties x${uniqueCompetences.length}`}
                    items3={`vaardigheden x${uniqueSkills.length}`}
                    cardId={task.id}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
      <WorkProcesses studyDetail={studyDetail} matrix={matrix} />
      <Competences studyDetail={studyDetail} />
    </Container>
  );
}
